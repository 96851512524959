import {
  DEFAULT_ORDER_BY_INGESTION_ACCOUNTS,
  DEFAULT_SORT_BY_INGESTION_ACCOUNTS
} from "../../utils/constants/reducerConstants";
import { createSlice } from "@reduxjs/toolkit";

import {
  IIngestionAccountsCardDetails,
  IIngestionAccountsTableResponse
} from "interfaces/ingestionAccounts.interface";
import { getInitialAccountCardContent } from "../../utils/HelperFunctions";

interface IngestionAccountsInterface {
  ingestionAccountsCardContent: IIngestionAccountsCardDetails[];
  searchKeyWord: string;
  tableResponse: IIngestionAccountsTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  isActive: string;
}

const initialState: IngestionAccountsInterface = {
  ingestionAccountsCardContent: getInitialAccountCardContent(),
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_INGESTION_ACCOUNTS,
  sortByColumn: DEFAULT_SORT_BY_INGESTION_ACCOUNTS,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  isActive: ""
};

const ingestionAccountsSlice = createSlice({
  name: "ingestionAccounts",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setIngestionAccountsCardContent: (state, action) => {
      state.ingestionAccountsCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setInitIngestionAccounts: () => {
      return initialState;
    },
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    }
  }
});

export const {
  setSearchKeyWord,
  setIngestionAccountsCardContent,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setInitIngestionAccounts,
  setIsActive
} = ingestionAccountsSlice.actions;

export default ingestionAccountsSlice.reducer;
