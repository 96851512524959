import React, { SetStateAction } from "react";
import styles from "./PaginationBar.module.scss";
import { staticStrings } from "@staticStrings";
import { ClayPaginationBarWithBasicItems } from "@clayui/pagination-bar";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import SpriteIcon from "@images/icons.svg";

interface PaginationBarProps {
  pageSize: number;
  setSelectedPage: (newPage: SetStateAction<number>) => AnyAction;
  currentPageNumber: number;
  dataExistFlag: boolean;
  totalItems: number;
  paginationBarid: string;
}

const PaginationBar: React.FC<PaginationBarProps> = ({
  pageSize,
  setSelectedPage,
  currentPageNumber,
  dataExistFlag,
  totalItems,
  paginationBarid
}) => {
  const dispatch = useDispatch();
  if (dataExistFlag) {
    if (totalItems > pageSize) {
      return (
        <ClayPaginationBarWithBasicItems
          activeDelta={pageSize}
          active={currentPageNumber}
          ellipsisBuffer={2}
          onActiveChange={(newPage: React.SetStateAction<number>) =>
            dispatch(setSelectedPage(newPage))
          }
          onClick={(e) => {
            const target = e.target;
            if (target instanceof HTMLElement && target.tagName === "BUTTON") {
              const dataFocus = target.getAttribute("data-focus");

              // Check if the data-focus attribute exists
              if (dataFocus !== null) {
                // Print or use the value of the data-focus attribute
                dispatch(setSelectedPage(Number(dataFocus)));
              }
            }
          }}
          totalItems={totalItems || 0}
          defaultActive={1}
          ellipsisProps={{ "aria-label": "More", title: "More" }}
          showDeltasDropDown={false}
          spritemap={SpriteIcon}
          labels={{
            paginationResults: dataExistFlag
              ? "{0} - {1} records of {2}"
              : "0 records",
            perPageItems: "{0} items",
            selectPerPageItems: "{0} items"
          }}
          id={paginationBarid}
        />
      );
    }
    return <></>;
  }
  return (
    <div className={styles.tableContainerTableNoData}>
      {staticStrings.commonTexts.noRecordsFound}
    </div>
  );
};

export { PaginationBar };
