import styles from "./FilterIngestionTable.module.scss";
import { predefinedValues } from "@pages/filterIngestion/Tabs/FilterIngestion/FilterIngestionConstants";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderBy,
  setSortByColumn,
  setSelectedPage,
  setSelectedFilterId
} from "../../store/filterIngestion/filterIngestion.slice";
import { Sorting } from "@clayui/core/lib/table/context";
import { RootState } from "store/configureStore";
import {
  IFilterIngestionTable,
  IFilterIngestionTableData,
  ITableHeader
} from "interfaces/filterIngestion.interface";
import { Cell, Row, useModal } from "@clayui/core";
import ActionsThreeDot from "@images/ActionsThreeDots.svg";
import { ReactComponent as DeleteTitleIcon } from "@images/delete-icon-red.svg";
import moment from "moment";
import { staticStrings } from "../../utils/constants/staticStrings";
import { capitalizeFirstLetter } from "../../utils/HelperFunctions";
import { SalesModal, SingleValueCell, TableList } from "@common-components";
import PermissionChecker from "../../common-ui-modules/common-components/PermissionChecker/PermissionChecker";
import { user_permissions } from "../../utils/constants/permissions";
import { PaginationBar } from "../../common-ui-modules/common-components/PaginationBar/PaginationBar";
import { staticIds } from "@staticIds";
import DropDown from "@clayui/drop-down/lib/DropDown";
import Button from "@clayui/button";

interface FilterIngestionTableProps {
  tableHeader: ITableHeader[];
  handleDeleteFilter: () => void;
  setRowData: (data: IFilterIngestionTableData) => void;
  onOpenChangeFilterIngestion: (status: boolean) => void;
  isLoading?: boolean;
}
interface ITableActions {
  name: string;
  state: boolean;
}

const FilterIngestionTable: React.FC<FilterIngestionTableProps> = ({
  tableHeader,
  handleDeleteFilter,
  isLoading
}) => {
  const [sort, setSort] = useState<Sorting | null>(null);
  const { observer, onOpenChange, open } = useModal();
  const [isDeleting, setIsDeleting] = useState("");
  const [openActionButtonList, setOpenActionButtonList] = useState<
    ITableActions[]
  >([]);
  const dispatch = useDispatch();

  const { tableResponse, selectedFilterId } = useSelector(
    (state: RootState) => state.filterIngestion
  );

  const onSortChange = (sort: Sorting | null) => {
    if (sort) {
      dispatch(setOrderBy(sort.direction));
      dispatch(setSortByColumn(sort.column));
    }
    setSort(sort);
  };

  const renderPaginationBar = (items: IFilterIngestionTable) => {
    return (
      <PaginationBar
        pageSize={predefinedValues.pageSize}
        setSelectedPage={setSelectedPage}
        currentPageNumber={tableResponse?.response.page_no}
        dataExistFlag={items?.data && items?.data.length > 0}
        totalItems={items?.total}
        paginationBarid="pagination_bar_filteringestion"
      />
    );
  };

  const cancelDelete = () => {
    onOpenChange(false);
    setIsDeleting("");
  };

  const getButtonList = (
    buttonList: ITableActions[],
    row: IFilterIngestionTableData
  ) => {
    return buttonList.map((obj) =>
      obj.name === row.id ? { ...obj, state: !obj?.state } : obj
    );
  };

  useEffect(() => {
    if (tableResponse?.response.data.length > 0) {
      setOpenActionButtonList([]);
      tableResponse?.response.data.forEach((row: IFilterIngestionTableData) => {
        setOpenActionButtonList((data: any) => {
          return [...data, { name: row.id, state: false }];
        });
      });
    }
  }, [tableResponse]);

  const updateActiveChange = (
    state: boolean,
    row: IFilterIngestionTableData
  ) => {
    if (state === false) {
      setOpenActionButtonList((prevObj) =>
        prevObj.map((obj) => {
          return obj?.state === true && obj.name === row.id
            ? { ...obj, state: false }
            : obj;
        })
      );
    } else {
      setOpenActionButtonList((prevObj) =>
        prevObj.map((obj) => {
          return obj?.state === true && obj.name !== row.id
            ? { ...obj, state: false }
            : obj;
        })
      );
    }
  };

  return (
    <div className={styles.tableContainer} id="table_filteringestion">
      <TableList
        onSortChange={onSortChange}
        sort={sort}
        header={tableHeader}
        isTableLoading={isLoading}
      >
        {tableResponse?.response.data &&
          tableResponse?.response.data.map(
            (row: IFilterIngestionTableData, index: number) => {
              return (
                <Row key={row.id} className={styles.tableContainerTableBodyRow}>
                  <SingleValueCell
                    cellValue={capitalizeFirstLetter(row.filter_type)}
                  />
                  <SingleValueCell
                    cellValue={capitalizeFirstLetter(row.filter_field)}
                  />
                  <SingleValueCell cellValue={row.pattern} />

                  <Cell>
                    <div>
                      <div
                        className={
                          styles.tableContainerTableBodyRowCellTextBold
                        }
                      >
                        <span>{row.last_updated_by ?? "---"}</span>
                      </div>
                      {row.last_updated_at && (
                        <div
                          className={
                            styles.tableContainerTableBodyRowCellTextGrey
                          }
                        >
                          {moment(row.last_updated_at).format(
                            staticStrings.dateFormats
                              .DEFAULT_UI_DATE_TIME_FORMAT
                          )}
                        </div>
                      )}
                    </div>
                  </Cell>
                  <Cell>
                    <PermissionChecker
                      requiredPermission={
                        user_permissions.customFilters.MANAGE_CUSTOM_FILTERS
                      }
                    >
                      {openActionButtonList &&
                      openActionButtonList.length > 0 ? (
                        <DropDown
                          key={row.id}
                          active={
                            openActionButtonList[
                              openActionButtonList.findIndex(
                                (obj) => obj.name === row.id
                              )
                            ]?.state ?? false
                          }
                          className={styles.dropDown}
                          filterKey=""
                          onActiveChange={(state: any) => {
                            updateActiveChange(state, row);
                          }}
                          trigger={
                            <Button
                              className={styles.dropDownBtn}
                              displayType="unstyled"
                              onClick={() => {
                                dispatch(setSelectedFilterId(row.id));
                                setOpenActionButtonList((prevObj) => {
                                  return getButtonList(prevObj, row);
                                });
                              }}
                            >
                              <div className={styles.dropDownText}>
                                {" "}
                                <img src={ActionsThreeDot} alt="edit" />
                              </div>
                            </Button>
                          }
                        >
                          {selectedFilterId === row.id && (
                            <DropDown.ItemList className={styles.dropDownItem}>
                              <DropDown.Item
                                key={row.id}
                                onClick={() => {
                                  dispatch(setSelectedFilterId(row.id));
                                  setOpenActionButtonList((prevObj) => {
                                    return getButtonList(prevObj, row);
                                  });
                                  onOpenChange(true);
                                  setIsDeleting(row.id);
                                }}
                                style={{
                                  paddingTop: "0.62rem"
                                }}
                                id={"dropdown_list_filteringestion_delete"}
                              >
                                {staticStrings.filterIngestion.delete}
                              </DropDown.Item>
                            </DropDown.ItemList>
                          )}
                        </DropDown>
                      ) : (
                        <></>
                      )}
                    </PermissionChecker>
                  </Cell>
                </Row>
              );
            }
          )}
      </TableList>
      {tableResponse.response &&
        !isLoading &&
        renderPaginationBar(tableResponse.response)}

      {isDeleting === selectedFilterId && (
        <SalesModal
          modalId={staticIds.filterIngestion.deleteModal}
          open={open}
          observer={observer}
          titleIcon={<DeleteTitleIcon />}
          title={staticStrings.filterIngestion.confirmationTitleFilterDelete}
          onSubmit={() => {
            handleDeleteFilter();
            onOpenChange(false);
          }}
          onCancel={cancelDelete}
          onClose={cancelDelete}
          submitText={staticStrings.filterIngestion.delete}
          cancelText={staticStrings.filterIngestion.cancel}
        >
          <div className={styles.deleteModalContent}>
            {staticStrings.filterIngestion.confirmationBodyFilterDelete}
          </div>
        </SalesModal>
      )}
    </div>
  );
};

export default FilterIngestionTable;
