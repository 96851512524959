import { createSlice } from "@reduxjs/toolkit";
import { IShowUserTeamTableResponse } from "interfaces/userManagement.interface";
import {
  DEFAULT_SORT_BY_CUSTOM_TEAM_DETAILS,
  DEFAULT_ORDER_BY_CUSTOM_TEAM_DETAILS
} from "../../../utils/constants/reducerConstants";

interface ITeamDetailsInterface {
  tableResponse: IShowUserTeamTableResponse;
  teamId: number;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  searchKeyWord: string;
  removeUsers: string[];
  addUsers: string[];
}

const initialState: ITeamDetailsInterface = {
  orderBy: DEFAULT_ORDER_BY_CUSTOM_TEAM_DETAILS,
  sortByColumn: DEFAULT_SORT_BY_CUSTOM_TEAM_DETAILS,
  selectedPage: 1,
  removeUsers: [],
  addUsers: [],
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  teamId: 0,
  searchKeyWord: ""
};

const customTeamDetailsSlice = createSlice({
  name: "customTeamDetails",
  initialState,
  reducers: {
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setRemoveUsers: (state, action) => {
      state.removeUsers = action.payload;
    },
    setAddUsers: (state, action) => {
      state.addUsers = action.payload;
    }
  }
});

export const {
  setTableResponse,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setSearchKeyWord,
  setRemoveUsers,
  setAddUsers
} = customTeamDetailsSlice.actions;
export default customTeamDetailsSlice.reducer;
