import { Body, Cell, Head, Table } from "@clayui/core";
import styles from "./TableList.module.scss";
import React from "react";
import { Sorting } from "@clayui/core/lib/table/context";
import UpDownArrow from "@images/UpDownArrow.png";
import infoIcon from "@images/info.svg";
import { ITableHeader } from "interfaces/filterIngestion.interface";
import { Tooltip } from "react-tooltip";
import { ClayCheckbox } from "@clayui/form";
import { ContainerLoader } from "../container-loader/ContainerLoader";
import { staticStrings } from "@staticStrings";

interface TableListProps {
  header: ITableHeader[];
  isTableLoading?: boolean;
  onSortChange?: (sort: Sorting | null) => void;
  sort?: Sorting | null;
  children?: React.ReactNode;
  toggleSelectAll?: () => void;
  selectAll?: boolean;
  isCheckBox?: boolean;
  disabled?: boolean;
}

const TableList: React.FC<TableListProps> = ({
  onSortChange,
  sort,
  header,
  children,
  toggleSelectAll,
  selectAll = false,
  isCheckBox = false,
  isTableLoading,
  disabled = false
}) => {
  return (
    <>
      <Table
        onSortChange={onSortChange}
        borderless
        striped={false}
        sort={sort}
        className={styles.tableContainerTable}
      >
        {!isTableLoading && (
          <Head className={styles.tableContainerTableHead}>
            {header?.map((column: any, index) => {
              if (
                column.id === staticStrings.commonTexts.checkboxLabel &&
                !isCheckBox
              )
                return false;
              return (
                <Cell
                  key={column.id}
                  sortable={column?.sortable}
                  className={
                    styles.textTruncateContainer +
                    " " +
                    styles.tableContainerTableHeadCell +
                    " " +
                    (styles[column.customClass] ?? column.customClass) +
                    " " +
                    (!column?.sortable
                      ? styles.tableContainerTableHeadEmptyCell
                      : "")
                  }
                  textValue={column.id}
                  id={`table_column_${column.id}`}
                >
                  <div
                    className={
                      styles.tableContainerTableHeadCellTextIconContainer +
                        " " +
                        styles[column.customHeaderContainerClass] ??
                      column.customHeaderContainerClass
                    }
                  >
                    {column.id === staticStrings.commonTexts.checkboxLabel &&
                      isCheckBox &&
                      !isTableLoading && (
                        <ClayCheckbox
                          className="mr-2"
                          checked={selectAll}
                          onChange={toggleSelectAll}
                          disabled={disabled}
                        />
                      )}
                    {column.id !== "checkbox" && (
                      <span className="mr-2">{column.name}</span>
                    )}

                    {column.tooltip && (
                      <>
                        <img
                          src={infoIcon}
                          alt="info"
                          data-tooltip-id={"tooltip" + index}
                          className={styles.tooltipTrigger}
                        />
                        <Tooltip
                          id={"tooltip" + index}
                          place="right"
                          positionStrategy="fixed"
                          offset={20}
                          className={`${styles.headerTooltip} increase-z-index`}
                          html={`${column.tooltip}`}
                        ></Tooltip>
                      </>
                    )}

                    {column?.sortable && <img src={UpDownArrow} alt="" />}
                  </div>
                </Cell>
              );
            })}
          </Head>
        )}
        <Body className={styles.tableContainerTableBody}>
          {!isTableLoading && children}
        </Body>
      </Table>
      {isTableLoading && <ContainerLoader />}
    </>
  );
};

export { TableList };
