import { IAccountTableResponse, ITreeResponse } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_SORT_BY_ACCOUNT,
  DEFAULT_ORDER_BY_ACCOUNT
} from "../../../utils/constants/reducerConstants";

interface AccountReportInterface {
  orderBy: string;
  sortByColumn: string;
  userFilterList: string[];
  tableResponse: IAccountTableResponse | {};
  hierarchyTreeResponse: ITreeResponse | {};
  heirarchyTreeResponseLoader: boolean;
  selectedPage: number;
  selectedAccountDivisions: string[];
}

const initialState: AccountReportInterface = {
  orderBy: DEFAULT_ORDER_BY_ACCOUNT,
  sortByColumn: DEFAULT_SORT_BY_ACCOUNT,
  userFilterList: [],
  tableResponse: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  hierarchyTreeResponse: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPage: 1,
  heirarchyTreeResponseLoader: true,
  selectedAccountDivisions: []
};

const accountReportSlice = createSlice({
  name: "accountReport",
  initialState,
  reducers: {
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setUserFilterList: (state, action) => {
      state.userFilterList = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setHierarchyTreeResponse: (state, action) => {
      state.hierarchyTreeResponse = action.payload;
    },
    setHeirarchyTreeResponseLoader: (state, action) => {
      state.heirarchyTreeResponseLoader = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedAccountDivisions: (state, action) => {
      state.selectedAccountDivisions = action.payload;
    },
    setInitAccountReporting: () => {
      return initialState;
    }
  }
});

export const {
  setOrderBy,
  setSortByColumn,
  setUserFilterList,
  setTableResponse,
  setSelectedPage,
  setHierarchyTreeResponse,
  setHeirarchyTreeResponseLoader,
  setSelectedAccountDivisions,
  setInitAccountReporting
} = accountReportSlice.actions;
export default accountReportSlice.reducer;
