import { lazyRetry } from "../../Routes/lazyRetry";
import Logout from "@components/Logouts/Logout";
import Ingestion from "@pages/filterIngestion/Ingestion";
import { IRoute } from "interfaces";
import React, { ComponentType } from "react";
import homeIcon from "@images/home.svg";
import reportingIcon from "@images/reporting.svg";
import ingestionIcon from "@images/filter-ingestion.svg";
import { user_permissions } from "./permissions";
import userManagementIcon from "@images/users-team.svg";
import integrationIcon from "@images/IntegrationIcon.svg";

export enum ROUTE_PATHS {
  HOME = "/",
  OKTA_LOGIN = "/login",
  OKTA_LOGIN_CALLBACK = "/oauth2/callback",
  LOCAL_LOGIN = "/local-login",
  LOGOUT = "/logout",
  HEALTH_CHECK = "/health-check",
  ACCOUNT_360 = "/account-360",
  REPORTING = "/reporting",
  NO_ACCESS = "/no-access",
  INGESTION = "/ingestion",
  USER_MANAGEMENT = "/user-management",
  NOT_FOUND_404 = "/page-not-found",
  CREATE_TEAMS = "/user-management/create-teams",
  CUSTOM_TEAM_DETAILS = "/user-management/custom-team-details",
  CUSTOM_TEAM_REPORTING_DETAILS = "/reporting/custom-team-details/:id",
  CUSTOM_TEAM_REPORTING_DETAILS_BASE = "/reporting/custom-team-details/",
  DOMAIN_DETAILS_INGESTION_DOMAINS = "/ingestion/domain-details",
  INTEGRATION = "/integration",
  USER_MANAGEMENT_ACCOUNTS = "/user-management/accounts"
}
const Account360 = React.lazy(
  () =>
    lazyRetry(() => import("@pages/account360/Account360")) as Promise<{
      default: ComponentType<any>;
    }>
);
const Reporting = React.lazy(
  () =>
    lazyRetry(() => import("@pages/reporting/Reporting")) as Promise<{
      default: ComponentType<any>;
    }>
);
const UserManagement = React.lazy(
  () =>
    lazyRetry(() => import("@pages/userManagement/UserManagement")) as Promise<{
      default: ComponentType<any>;
    }>
);
const CreateCustomTeam = React.lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "@pages/userManagement/Tabs/CustomTeams/create-custom-teams/CreateCustomTeams"
        )
    ) as Promise<{
      default: ComponentType<any>;
    }>
);
const TeamDetails = React.lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "@pages/userManagement/Tabs/CustomTeams/custom-team-details/CustomTeamDetails"
        )
    ) as Promise<{
      default: ComponentType<any>;
    }>
);
const CustomTeamReportingDetails = React.lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "@components/CustomTeamReportingDetails/CustomTeamReportingDetails"
        )
    ) as Promise<{
      default: ComponentType<any>;
    }>
);
const DomainDetails = React.lazy(
  () =>
    lazyRetry(
      () =>
        import(
          "@pages/filterIngestion/Tabs/Domains/DomainDetails/DomainDetails"
        )
    ) as Promise<{
      default: ComponentType<any>;
    }>
);

const Integration = React.lazy(
  () =>
    lazyRetry(() => import("@pages/integration/Integration")) as Promise<{
      default: ComponentType<any>;
    }>
);

const UserManagementAccounts = React.lazy(
  () =>
    lazyRetry(
      () => import("@pages/userManagement/Tabs/Users/Accounts/Accounts")
    ) as Promise<{
      default: ComponentType<any>;
    }>
);

export const routesConfig: IRoute[] = [
  {
    id: 1,
    path: ROUTE_PATHS.HOME,
    component: Account360,
    pageTitle: false,
    name: "Home",
    sideNav: false,
    icon: homeIcon,
    permissionRequired: true,
    userPermission: user_permissions.account360.VIEW_ACCOUNT_360
  },
  {
    id: 2,
    path: ROUTE_PATHS.ACCOUNT_360,
    component: Account360,
    pageTitle: false,
    name: "Home",
    sideNav: true,
    icon: homeIcon,
    permissionRequired: true,
    userPermission: user_permissions.account360.VIEW_ACCOUNT_360
  },
  {
    id: 3,
    path: ROUTE_PATHS.REPORTING,
    component: Reporting,
    pageTitle: false,
    name: "Reporting",
    sideNav: true,
    icon: reportingIcon,
    permissionRequired: true,
    userPermission: user_permissions.reporting.VIEW_REPORTING
  },
  {
    id: 4,
    path: ROUTE_PATHS.LOGOUT,
    component: Logout,
    pageTitle: false,
    name: "Logout",
    permissionRequired: false,
    userPermission: ""
  },
  {
    id: 5,
    path: ROUTE_PATHS.USER_MANAGEMENT,
    component: UserManagement,
    pageTitle: false,
    name: "User Management",
    sideNav: true,
    icon: userManagementIcon,
    permissionRequired: true,
    userPermission: user_permissions.userManagement.MANAGE_USERS
  },
  {
    id: 6,
    path: ROUTE_PATHS.INGESTION,
    component: Ingestion,
    pageTitle: true,
    name: "Ingestion",
    sideNav: true,
    icon: ingestionIcon,
    permissionRequired: true,
    userPermission: user_permissions.customFilters.VIEW_CUSTOM_FILTERS
  },
  {
    id: 7,
    path: ROUTE_PATHS.CREATE_TEAMS,
    component: CreateCustomTeam,
    pageTitle: true,
    name: "Create Custom Teams",
    sideNav: false,
    permissionRequired: true,
    userPermission: user_permissions.userManagement.MANAGE_CUSTOM_TEAM
  },
  {
    id: 8,
    path: ROUTE_PATHS.CUSTOM_TEAM_DETAILS,
    component: TeamDetails,
    pageTitle: true,
    name: "Team Details",
    sideNav: false,
    permissionRequired: true,
    userPermission: user_permissions.userManagement.MANAGE_CUSTOM_TEAM
  },
  {
    id: 9,
    path: ROUTE_PATHS.CUSTOM_TEAM_REPORTING_DETAILS,
    component: CustomTeamReportingDetails,
    pageTitle: false,
    name: "Custom Team Reporting Details",
    sideNav: false,
    permissionRequired: false
  },
  {
    id: 10,
    path: ROUTE_PATHS.DOMAIN_DETAILS_INGESTION_DOMAINS,
    component: DomainDetails,
    pageTitle: true,
    name: "Domain Details",
    sideNav: false,
    permissionRequired: true,
    userPermission: user_permissions.customFilters.VIEW_CUSTOM_FILTERS
  },
  {
    id: 11,
    path: ROUTE_PATHS.INTEGRATION,
    component: Integration,
    pageTitle: true,
    name: "Integration",
    sideNav: true,
    icon: integrationIcon,
    permissionRequired: true,
    userPermission: user_permissions.integration.VIEW_ELOQUA_FILTER
  },
  {
    id: 12,
    path: ROUTE_PATHS.USER_MANAGEMENT_ACCOUNTS,
    component: UserManagementAccounts,
    pageTitle: true,
    name: "User Accounts",
    sideNav: false,
    permissionRequired: true,
    userPermission: user_permissions.userManagement.MANAGE_USERS
  }
];
