import { configureStore } from "@common-services";

import account360Reducer from "./account360/account360.slice";

import activityTrendsReducer from "./account360/activityTrends.slice";
import engagedPeopleReducer from "./account360/engagedPeople/engagedPeople.slice";
import accountReportReducer from "./reporting/account/accountReport.slice";
import filterIngestionReducer from "./filterIngestion/filterIngestion.slice";
import accountTeamReducer from "./account360/accountTeam/accountTeam.slice";
import peopleReportingReducer from "./reporting/people/peopleReport.slice";
import configReducer from "./config/config.slice";
import userManagementReducer from "./userManagement/userManagement.slice";
import externalConnectionsReducer from "./account360/engagedPeople/externalConnections.slice";
import showUsersReducer from "./userManagement/show-users/showUsers.slice";
import customTeamReducer from "./userManagement/customTeam/customTeam.slice";
import customTeamDetailsReducer from "./userManagement/custom-team-details/customTeamDetails.slice";
import customTeamReportingReducer from "./reporting/customTeam/customTeamReporting.slice";
import customTeamReportingDetailsReducer from "./reporting/customTeam/customTeamReportingDetails.slice";
import reportingReducer from "./reporting/reporting.slice";
import domainsReducer from "./filterIngestion/domains/domains.slice";
import domainDetailsReducer from "./filterIngestion/domain-details/domaindetails.slice";
import ingestionAccountsReducer from "./ingestionAccounts/ingestionAccounts.slice";
import eloquaReducer from "./integration/eloqua/eloquaIntegration.slice";
import userAccountsReducer from "./userManagement/accounts/userAccounts.slice";

export const store: any = configureStore({
  reducer: {
    account360: account360Reducer,
    reporting: reportingReducer,
    activityTrends: activityTrendsReducer,
    engagedPeople: engagedPeopleReducer,
    accountReport: accountReportReducer,
    filterIngestion: filterIngestionReducer,
    accountTeam: accountTeamReducer,
    peopleReport: peopleReportingReducer,
    config: configReducer,
    userManagement: userManagementReducer,
    externalConnections: externalConnectionsReducer,
    showUsers: showUsersReducer,
    customTeam: customTeamReducer,
    customTeamDetails: customTeamDetailsReducer,
    customTeamReporting: customTeamReportingReducer,
    customTeamReportingDetails: customTeamReportingDetailsReducer,
    domains: domainsReducer,
    domainDetails: domainDetailsReducer,
    ingestionAccounts: ingestionAccountsReducer,
    eloqua: eloquaReducer,
    userAccounts: userAccountsReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
