import { Header } from "@common-components";
import { getIsOktaAuth } from "@common-services";
import PageNotFound from "@components/pageNotFound/PageNotFound";
import { SideNav } from "@components/sideNav/SideNav";
import { ROUTE_PATHS, routesConfig } from "@constants";
import { IRoute } from "@interfaces";
import { OktaAuth } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { usePermission, isHomePath } from "@utils";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { RequiredAuth } from "./SecureRoute";
import { staticStrings } from "@staticStrings";
import UnauthorizedImage from "../../../../assets/images/unauthorized_message.svg";
import "./RouteStyles.scss";

function OktaAuthentication() {
  const isOktaAuthenticated = useSelector(getIsOktaAuth);
  const location = useLocation();

  const oktaAuth = new OktaAuth({
    issuer: window.env.REACT_APP_OKTA_ISSUER_URL,
    clientId: window.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + ROUTE_PATHS.OKTA_LOGIN,
    responseType: "code",
    responseMode: "query",
    codeChallengeMethod: "S256",
    scopes: ["openid", "email"],
    tokenManager: { storage: "sessionStorage" }
  });
  const hideSideNav = isHomePath(location.pathname);

  const SecureRoutes = routesConfig.map((route: IRoute) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasPermission = usePermission(
      route.userPermission,
      !route.permissionRequired
    );

    return (
      <Route key={route.id} path={route.path} element={<RequiredAuth />}>
        <>
          {isOktaAuthenticated ? (
            <Route
              path={route.path}
              element={
                <>
                  <div className="main-container">
                    <div className="header">
                      <Header />
                    </div>
                    <div className="d-flex">
                      <div className="navigation">
                        {!hideSideNav && <SideNav />}
                      </div>
                      {hasPermission ? (
                        <div className="routes flex-grow-1 sip-container max-width mx-auto overflow-auto min-vh-50">
                          <route.component />
                        </div>
                      ) : (
                        <div className="unauthorizzed-container">
                          <p className="unauthorized-text">
                            {
                              staticStrings.auth_error_screens
                                .unauthorized_access
                            }
                          </p>
                          <img
                            src={UnauthorizedImage}
                            alt="Unauthorized Access"
                            className="unauthorized-image"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              }
            />
          ) : (
            <></>
          )}
        </>
      </Route>
    );
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    const url = new URL(`${window.location.href}${originalUri}`);
    window.location.href =
      !originalUri || url.pathname === ROUTE_PATHS.ACCOUNT_360
        ? ROUTE_PATHS.ACCOUNT_360
        : originalUri;
  };

  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          <Route
            path={ROUTE_PATHS.OKTA_LOGIN}
            element={<LoginCallback loadingElement={<></>} />}
          />
          {SecureRoutes}
          <Route
            path={ROUTE_PATHS.HEALTH_CHECK}
            element={<h1>Application is healthy</h1>}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Security>
    </>
  );
}

export default OktaAuthentication;
