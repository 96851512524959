import { staticStrings } from "@staticStrings";
import styles from "./FilterIngestionHeader.module.scss";
import SearchInput from "@components/SearchInput/SearchInput";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "@utils";
import { DropdownSelectBox } from "@common-components";
import {
  setFilterTypeParam,
  setFilterFieldParam,
  setSelectedPage
} from "../../store/filterIngestion/filterIngestion.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import infoIcon from "@images/info.svg";
import { capitalizeFirstLetter } from "../../utils/HelperFunctions";
import { IFilterIngestionTableData } from "interfaces/filterIngestion.interface";
import PermissionChecker from "../../common-ui-modules/common-components/PermissionChecker/PermissionChecker";
import { user_permissions } from "../../utils/constants/permissions";
import CustomButton from "../../common-ui-modules/common-components/CustomButton/CustomButton";

interface FilterIngestionHeaderProps {
  setSearchKeyWord: (keyWord: string) => void;
  onOpenChange: (status: boolean) => void;
  setRowData: (data: IFilterIngestionTableData | undefined) => void;
}

const FilterIngestionHeader: React.FC<FilterIngestionHeaderProps> = ({
  setSearchKeyWord,
  onOpenChange,
  setRowData
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [fieldData, setFieldData] = useState<string[]>([]);
  const [typeData, setTypeData] = useState<string[]>([]);
  const dispatch = useDispatch();

  const { filterTypeParam, filterFieldParam, filterOptionsResponse } =
    useSelector((state: RootState) => state.filterIngestion);

  useEffect(() => {
    const typeDataArray = [staticStrings.filterIngestion.any];
    let fieldArray = [staticStrings.filterIngestion.any];
    for (let key in filterOptionsResponse.response) {
      if (filterOptionsResponse.response.hasOwnProperty(key)) {
        typeDataArray.push(capitalizeFirstLetter(key));
        if (key.toLowerCase() === filterTypeParam.toLowerCase()) {
          filterOptionsResponse.response[key].forEach((fieldOption: string) => {
            fieldArray.push(capitalizeFirstLetter(fieldOption));
          });
        }
      }
    }
    setFieldData([...fieldArray]);
    setTypeData(typeDataArray);
  }, [filterTypeParam, dispatch, filterOptionsResponse]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce(setSearchKeyWord, 500),
    [setSearchKeyWord]
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };

  const handleFilterType = (value: string) => {
    dispatch(setFilterTypeParam(value));
    dispatch(setFilterFieldParam(staticStrings.filterIngestion.any));
    dispatch(setSelectedPage(1));
  };

  const handleFilterField = (value: string) => {
    dispatch(setFilterFieldParam(capitalizeFirstLetter(value)));
    dispatch(setSelectedPage(1));
  };

  return (
    <div className={styles.ingestionHeader}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchFilterContainer}>
          <SearchInput
            onChange={handleSearch}
            searchValue={searchInput}
            placeholder={staticStrings.filterIngestion.searchPlaceHolder}
          />
        </div>
        <span>{staticStrings.filterIngestion.type}</span>
        <div className={styles.typeFilterContainer}>
          <DropdownSelectBox
            selectedOption={filterTypeParam}
            options={Array.from(new Set(typeData))}
            setSelectedOption={handleFilterType}
            dropdownId="type_dropdown"
          />
        </div>
        <span>{staticStrings.filterIngestion.field}</span>
        <div className={styles.fieldFilterContainer}>
          <DropdownSelectBox
            selectedOption={filterFieldParam}
            options={Array.from(new Set(fieldData))}
            setSelectedOption={handleFilterField}
            dropdownId="field_dropdown"
          />
        </div>
        <PermissionChecker
          requiredPermission={
            user_permissions.customFilters.MANAGE_CUSTOM_FILTERS
          }
        >
          <CustomButton
            displayType="primary"
            onClick={() => {
              onOpenChange(true);
              setRowData(undefined);
            }}
            id="add_filter_button"
          >
            {staticStrings.filterIngestion.createButton}
          </CustomButton>
        </PermissionChecker>
      </div>
      <div className={styles.filterIngestionInfoContainer}>
        <div>
          <img
            src={infoIcon}
            alt="info"
            className={styles.filterIngestionInfoContainerImage}
          />
        </div>
        <div
          className={styles.filterIngestionInfoContainerText}
          id="filter_ingestion_information_section"
        >
          {staticStrings.filterIngestion.filterInfoText}
        </div>
      </div>
    </div>
  );
};

export default FilterIngestionHeader;
