import React from "react";
import styles from "./SalesModal.module.scss";
import ClayModal from "@clayui/modal";
import ClayButton from "@clayui/button";
import spritemap from "@images/icons.svg";
import ClayIcon from "@clayui/icon";
import CustomButton from "../CustomButton/CustomButton";

interface IPopUpProps {
  open: boolean;
  observer: any;
  onClose?: () => void;
  onSubmit?: () => void;
  onCancel?: () => void;
  titleIcon?: React.ReactNode;
  title?: string;
  submitText?: string;
  cancelText?: string;
  children?: React.ReactNode;
  disableAutoClose?: boolean;
  customClass?: string;
  layoutClass?: string;
  isSubmitDisabled?: boolean;
  modalId?: string;
}
const SalesModal: React.FC<IPopUpProps> = ({
  open,
  observer,
  titleIcon,
  title,
  submitText,
  onSubmit,
  cancelText,
  onCancel,
  children,
  onClose,
  disableAutoClose = true,
  customClass = "",
  layoutClass,
  isSubmitDisabled = false,
  modalId
}) => {
  return (
    <>
      {open && (
        <ClayModal
          id={modalId}
          observer={observer}
          spritemap={spritemap}
          className={`${styles.clayModal}  ${
            layoutClass ? styles[layoutClass] : ""
          }`}
          center={true}
          disableAutoClose={disableAutoClose}
        >
          <ClayModal.Header
            className={`${styles.modalHeader} ${
              customClass && styles[customClass]
            }`}
            withTitle={false}
          >
            <ClayModal.Item>
              {title && (
                <ClayModal.TitleSection>
                  <ClayModal.Title>
                    {titleIcon && (
                      <ClayModal.TitleIndicator>
                        {titleIcon}
                      </ClayModal.TitleIndicator>
                    )}
                    {title}
                  </ClayModal.Title>
                </ClayModal.TitleSection>
              )}
            </ClayModal.Item>

            <ClayButton
              aria-label="close"
              className={styles.closeButton}
              displayType="unstyled"
              onClick={onClose}
            >
              <ClayIcon spritemap={spritemap} symbol="times" />
            </ClayButton>
          </ClayModal.Header>
          {children && <ClayModal.Body>{children}</ClayModal.Body>}
          {(cancelText || submitText) && (
            <ClayModal.Footer
              last={
                <ClayButton.Group spaced>
                  {cancelText && (
                    <CustomButton
                      displayType="secondary"
                      onClick={onCancel}
                      disabled={isSubmitDisabled}
                      id="cancel_button"
                    >
                      {cancelText}
                    </CustomButton>
                  )}
                  {submitText && (
                    <CustomButton
                      onClick={onSubmit}
                      disabled={isSubmitDisabled}
                      displayType="primary"
                      id="submit_button"
                    >
                      {submitText}
                    </CustomButton>
                  )}
                </ClayButton.Group>
              }
            />
          )}
        </ClayModal>
      )}
    </>
  );
};

export { SalesModal };
