import { ClayIconSpriteContext } from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import spritemap from "@images/icons.svg";
import "@clayui/css/lib/css/atlas.css";
import styles from "./Ingestion.module.scss";
import { staticStrings } from "@staticStrings";
import Tabs from "./Tabs/Tabs";

const Ingestion = () => {
  return (
    <ClayIconSpriteContext.Provider value={spritemap}>
      <div className={styles.ingestionContainer}>
        <ClayLayout.Row className={styles.ingestionContainerRow}>
          <h1 className={"h1"}>{staticStrings.ingestion.ingestionTitle}</h1>
        </ClayLayout.Row>
        <Tabs />
      </div>
    </ClayIconSpriteContext.Provider>
  );
};
export default Ingestion;
