import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_ORDER_BY_ENGAGED_PEOPLE,
  COMMON_SORT_ENGAGED_PEOPLE
} from "../../../utils/constants/reducerConstants";
import { IEngagedExternalConnectionsResponse } from "interfaces/engagedPeople.interface";

interface ExternalConnectionsInterface {
  orderByExternalConnections: string;
  sortByColumnExternalConnections: string;
  tableResponse: IEngagedExternalConnectionsResponse | {};
  selectedPage: number;
}

const initialState: ExternalConnectionsInterface = {
  orderByExternalConnections: DEFAULT_ORDER_BY_ENGAGED_PEOPLE,
  sortByColumnExternalConnections: COMMON_SORT_ENGAGED_PEOPLE,
  tableResponse: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPage: 1
  // ... other initial settings
};

const exernalConnectionsSlice = createSlice({
  name: "externalConnections",
  initialState,
  reducers: {
    setOrderBy: (state, action) => {
      state.orderByExternalConnections = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumnExternalConnections = action.payload;
    },

    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },

    setInitStateExternalConnections: (state) => {
      return initialState;
    }

    // ... other actions
  }
});

export const {
  setOrderBy,
  setSortByColumn,
  setTableResponse,
  setInitStateExternalConnections
} = exernalConnectionsSlice.actions;
export default exernalConnectionsSlice.reducer;
