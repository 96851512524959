import SummaryBox from "@components/SummaryBox/SummaryBox";
import SearchInput from "@components/SearchInput/SearchInput";
import styles from "./Domains.module.scss";
import { useCallback, useEffect, useState } from "react";
import { staticStrings } from "@staticStrings";
import { debounce } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import { staticIds } from "@staticIds";
import {
  AccountListInterface,
  IAccountsFilter
} from "interfaces/domains.interface";
import { IDropDownApiResponse } from "interfaces/engagedPeople.interface";
import DropdownCheckBoxWithLazyLoading, {
  IList
} from "../../common-ui-modules/common-components/DropdownCheckBox/DropDownCheckBoxWithLazyLoading";
import { setAccountFilterListAndPageNumber } from "@store/filterIngestion/domains/domains.slice";
import {
  convertDateToUTC,
  getCurrentDateTimeinUTC
} from "../../utils/HelperFunctions";
import { PATHS } from "@constants";
import { axiosHttp } from "@common-services";
import { predefinedValuesAccounts } from "@pages/filterIngestion/Tabs/Domains/DomainsTableConstant";
import AccountsImage from "@images/userManagement/UserManagementTotal.svg";
import DomainsImage from "@images/DomainsIcon.svg";
import { HelperFunctions } from "@utils";

interface DomainsHeaderProps {
  setSearchKeyWord: (keyWord: string) => void;
  setAccountsFilter: (accountsFilter: IAccountsFilter) => void;
}

const DomainsHeader: React.FC<DomainsHeaderProps> = ({
  setSearchKeyWord,
  setAccountsFilter
}) => {
  const [clearClickFlag, setClearClickFlag] = useState<boolean>(false);
  const [loadingClearClick, setLoadingClearClick] = useState<boolean>(false);
  const [tempUnselectedValues, setTempUnselectedValues] = useState<string[]>(
    []
  );
  const [dropDownUpdateFlag, setDropDownUpdateFlag] = useState<boolean>(false);
  const [submitDisableFlag, setSubmitDisableFlag] = useState<boolean>(true);
  const [isLoadingDropDownContent, setIsLoadingDropDownContent] =
    useState(false);
  const [submitFlag, setSubmitFlag] = useState<boolean>(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);
  const [unfilteredList, setUnfilteredList] = useState<IList[]>([]);
  const [filteredList, setFilteredList] = useState<IList[]>([]);
  const { allAccounts, accountLabel, accountsLabel } =
    staticStrings.ingestion.domains;
  const [dropDownLabelForAccount, setDropDownLabelForAccount] =
    useState<string>(allAccounts);
  const [selectedAccount, setSelectedAccount] = useState<string[]>([]);
  const [totalAccount, setTotalAccount] = useState(0);
  const [unselectedAccount, setUnselectedAccount] = useState<string[]>([]);
  const [selectedAccountCount, setSelectedAccountCount] = useState(0);
  const [dataApiResponse, setDataApiResponse] = useState<IDropDownApiResponse>({
    error: null,
    response: { data: [], page_no: 0, total: 0 }
  });
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchKeyWordAccounts, setSearchKeyWordAccounts] =
    useState<string>("");
  const dispatch = useDispatch();
  const { domainsCardContent, searchKeyWord, accountFilterList } = useSelector(
    (state: RootState) => state.domains
  );
  const { startDate } = useSelector((state: RootState) => state.account360);

  useEffect(() => {
    setSearchInput(searchKeyWord);
  }, [searchKeyWord]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce(setSearchKeyWord, 500),
    [setSearchKeyWord]
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };
  const getDropDownLabelForAccounts = () => {
    if (selectedAccountCount === totalAccount) {
      return allAccounts;
    } else if (selectedAccountCount === 1) {
      return `${selectedAccountCount} ${accountLabel}`;
    }
    return `${selectedAccountCount} ${accountsLabel}`;
  };
  const formatAccountList = (account: AccountListInterface) => {
    return {
      id: account.id,
      value: account.id,
      name: `${account.name} - ${account.party_id}`
    };
  };

  useEffect(() => {
    if (selectedAccount.length !== selectedAccountCount) {
      if (clearClickFlag) {
        setAccountsFilter({
          action_type: staticStrings.account360.engagedPeople.include,
          account_ids: accountFilterList
        });
      } else {
        setAccountsFilter({
          action_type: staticStrings.account360.engagedPeople.exclude,
          account_ids: accountFilterList
        });
      }
    } else {
      if (
        selectedAccount.length <= unselectedAccount.length ||
        selectedAccount.length + unselectedAccount.length !== totalAccount
      ) {
        setAccountsFilter({
          action_type: staticStrings.account360.engagedPeople.include,
          account_ids: accountFilterList
        });
      } else {
        setAccountsFilter({
          action_type: staticStrings.account360.engagedPeople.exclude,
          account_ids: accountFilterList
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountFilterList]);

  useEffect(() => {
    setDropDownLabelForAccount(staticStrings.reporting.allAccounts);
    dispatch(setAccountFilterListAndPageNumber([]));
    setSearchKeyWordAccounts("");
    setFilteredList([]);
    setUnfilteredList([]);
    setSelectedAccount([]);
    setUnselectedAccount([]);
    setSelectedAccountCount(0);
    fetchDataForLazyLoading(`${getQueryParams(1)}`);
    setDropDownUpdateFlag(false);
    setSubmitDisableFlag(true);
    setClearClickFlag(false);
    setLoadingClearClick(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (submitFlag) {
      if (selectedAccount.length !== selectedAccountCount) {
        if (totalAccount === selectedAccountCount) {
          dispatch(setAccountFilterListAndPageNumber([]));
        } else if (clearClickFlag) {
          dispatch(setAccountFilterListAndPageNumber(selectedAccount));
        } else {
          dispatch(setAccountFilterListAndPageNumber(unselectedAccount));
        }
      } else {
        if (
          selectedAccount.length <= unselectedAccount.length ||
          (clearClickFlag &&
            totalAccount !== selectedAccount.length + unselectedAccount.length)
        ) {
          dispatch(setAccountFilterListAndPageNumber(selectedAccount));
        } else {
          dispatch(setAccountFilterListAndPageNumber(unselectedAccount));
        }
      }
      setDropDownLabelForAccount(getDropDownLabelForAccounts());
      setSubmitFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitFlag]);

  const setLoaderOnSearch = () => {
    if (searchKeyWordAccounts) setIsLoadingInitialData(true);
  };
  const updateTempUnselectedValuesOnClearButtonClick = (
    filteredIdArray: string[]
  ) => {
    if (clearClickFlag) {
      setTempUnselectedValues((prev) => [...prev, ...filteredIdArray]);
    }
  };
  const updateSubmitDropDownFlag = () => {
    if (submitDisableFlag) {
      setDropDownUpdateFlag(true);
    }
  };
  const updateSelectedAccount = (filteredIdArray: string[]) => {
    if (!loadingClearClick)
      setSelectedAccount((prev) =>
        Array.from(new Set([...prev, ...filteredIdArray]))
      );
    else {
      setUnselectedAccount((prev) =>
        Array.from(new Set([...prev, ...filteredIdArray]))
      );
    }
  };
  const fetchDataForLazyLoading = (params: string) => {
    const apiUrl: string = `${PATHS.ACCOUNT_360.ACCOUNT_LIST}?${params}`;
    setIsLoadingDropDownContent(true);
    setLoaderOnSearch();
    const controller = new AbortController();
    const signal = controller.signal;
    axiosHttp
      .get(apiUrl, { signal })
      .then((response) => {
        if (response?.data) {
          const accountList: IList[] =
            response.data.response.data.map(formatAccountList);
          const filteredData = accountList.filter(
            (item: IList) => !unselectedAccount.includes(item.id)
          );
          const filteredIdArray = filteredData.map((user) => user.id);
          if (searchKeyWordAccounts.length > 0) {
            setFilteredList(accountList);
            setSelectedAccount((prev) =>
              Array.from(new Set([...prev, ...filteredIdArray]))
            );
            updateTempUnselectedValuesOnClearButtonClick(filteredIdArray);
          } else {
            if (response?.data?.response?.page_no === 1) {
              setSelectedAccount(accountList.map((user) => user.id));
              setUnfilteredList(accountList);
              setTotalAccount(response?.data?.response?.total);
              setSelectedAccountCount(response?.data?.response?.total);
            } else {
              setUnfilteredList((prev) =>
                HelperFunctions.getUniqueDropdownItemList(prev, accountList)
              );
              updateSelectedAccount(filteredIdArray);
              updateTempUnselectedValuesOnClearButtonClick(filteredIdArray);
              updateSubmitDropDownFlag();
            }
            setDataApiResponse(response.data);
          }
        }
      })
      .catch((error) => {
        setIsLoadingDropDownContent(false);
      })
      .finally(() => {
        setIsLoadingDropDownContent(false);
        setIsLoadingInitialData(false);
      });
    return () => {
      controller.abort();
    };
  };
  const getQueryParams = (pageNumber = 1) => {
    const startDateUTC = convertDateToUTC(
      startDate,
      staticStrings.dateFormats.DMMMYY,
      staticStrings.dateFormats.YYYYMMDD_WITH_HOURS
    );
    const today = getCurrentDateTimeinUTC();
    return `startDateTime=${startDateUTC}&endDateTime=${today}&&pageNo=${pageNumber}&pageSize=${predefinedValuesAccounts.pageSize}&allAccounts=true`;
  };
  const loadMoreAccounts = async () => {
    if (dataApiResponse.response) {
      const maxPages = Math.ceil(
        dataApiResponse?.response?.total / predefinedValuesAccounts.pageSize
      );
      if (
        dataApiResponse?.response?.page_no < maxPages &&
        !isLoadingDropDownContent
      ) {
        let newPageNumber = dataApiResponse?.response?.page_no + 1;
        fetchDataForLazyLoading(`${getQueryParams(newPageNumber)}`);
      }
    }
  };

  useEffect(() => {
    if (searchKeyWordAccounts)
      fetchDataForLazyLoading(
        `&search=${encodeURIComponent(searchKeyWordAccounts)}&allAccounts=true`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyWordAccounts]);
  return (
    <div className={styles.domainsCardAndSearchSection}>
      <div className={styles.domainsCardOuterContainer}>
        {
          <div className={`${styles.domainsCard} `} key={AccountsImage}>
            <div className={styles.domainsCardExtraPadding}>
              <SummaryBox
                headerText={domainsCardContent[0]?.headerText ?? "---"}
                bodyText={staticStrings.ingestion.domains.accountsCard}
                image={AccountsImage}
                summaryCardId={`${staticIds.ingestion.domains.accountsCard}0`}
              />
            </div>
          </div>
        }
        {
          <div className={`${styles.domainsCard} `} key={DomainsImage}>
            <div className={styles.domainsCardExtraPadding}>
              <SummaryBox
                headerText={domainsCardContent[1]?.headerText ?? "---"}
                bodyText={staticStrings.ingestion.domains.domainsCard}
                image={DomainsImage}
                summaryCardId={`${staticIds.ingestion.domains.accountsCard}1`}
              />
            </div>
          </div>
        }
      </div>

      <div className={styles.domainsSearchAndAddSection}>
        <div className={styles.domainsAccountsSection}>
          <div className={styles.textLabel}>
            {staticStrings.reporting.accounts.treeDropdownlabel}
          </div>
          <DropdownCheckBoxWithLazyLoading
            list={unfilteredList}
            searchResponse={filteredList}
            totalItems={totalAccount}
            dropdownLabel={dropDownLabelForAccount}
            selectedValues={selectedAccount}
            setSelectedValues={setSelectedAccount}
            unselectedValues={unselectedAccount}
            setUnselectedValues={setUnselectedAccount}
            selectedCount={selectedAccountCount}
            setSelectedCount={setSelectedAccountCount}
            loadMoreAccounts={loadMoreAccounts}
            isLoadingInitialData={isLoadingInitialData}
            setIsLoadingInitialData={setIsLoadingInitialData}
            isLoading={isLoadingDropDownContent}
            searchKeyWord={searchKeyWordAccounts}
            setSearchKeyWord={setSearchKeyWordAccounts}
            setSubmitFlag={setSubmitFlag}
            setClearClickFlag={setClearClickFlag}
            tempUnselectedValues={tempUnselectedValues}
            setTempUnselectedValues={setTempUnselectedValues}
            clearClickFlag={clearClickFlag}
            setSubmitDisableFlag={setSubmitDisableFlag}
            dropDownUpdateFlag={dropDownUpdateFlag}
            setDropDownUpdateFlag={setDropDownUpdateFlag}
            setLoadingClearClick={setLoadingClearClick}
            setUnfilteredList={setUnfilteredList}
          />
        </div>
        <div className={styles.searchdomainsContainer}>
          <SearchInput
            onChange={handleSearch}
            searchValue={searchInput}
            placeholder={staticStrings.ingestion.domains.searchPlaceHolder}
          />
        </div>
      </div>
    </div>
  );
};

export default DomainsHeader;
