import {
  DEFAULT_ORDER_BY_DOMAIN_INGESTION,
  DEFAULT_SORT_BY_DOMAIN_INGESTION
} from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import {
  IDomainsCardDetails,
  IIngestionDomainsTableResponse
} from "interfaces/domains.interface";

interface DomainsInterface {
  domainsCardContent: IDomainsCardDetails[];
  searchKeyWord: string;
  tableResponse: IIngestionDomainsTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  accountFilterList: string[];
  activeTab: number;
}

const initialState: DomainsInterface = {
  domainsCardContent: [],
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_DOMAIN_INGESTION,
  sortByColumn: DEFAULT_SORT_BY_DOMAIN_INGESTION,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  accountFilterList: [],
  activeTab: 0
};

const initApiState = {
  orderBy: DEFAULT_ORDER_BY_DOMAIN_INGESTION,
  sortByColumn: DEFAULT_SORT_BY_DOMAIN_INGESTION,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  searchKeyWord: "",
  accountFilterList: [],
  domainsCardContent: []
};

const domainsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setDomainsCardContent: (state, action) => {
      state.domainsCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setAccountFilterList: (state, action) => {
      state.accountFilterList = action.payload;
    },
    setAccountFilterListAndPageNumber: (state, action) => {
      state.selectedPage = 1;
      state.accountFilterList = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setInitDomainIngestion: (state) => {
      const stateObject = {
        ...state,
        ...initApiState
      };
      return stateObject;
    }
  }
});

export const {
  setSearchKeyWord,
  setDomainsCardContent,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setAccountFilterList,
  setAccountFilterListAndPageNumber,
  setInitDomainIngestion,
  setActiveTab
} = domainsSlice.actions;
export default domainsSlice.reducer;
