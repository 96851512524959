import ClayButton from "@clayui/button";
import ClayLayout from "@clayui/layout";
import React from "react";

function PageNotFound() {
  return (
    <div className="error-container m-2">
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row>
          <ClayLayout.Col size={6}>
            <ClayLayout.Row>
              <h1 className="pnf-header">
                The page you requested couldn&apos;t be found{" "}
              </h1>
            </ClayLayout.Row>
            <ClayLayout.Row justify={"start"} className="pt-4">
              <ClayLayout.Col size={4}>
                <a href="/">
                  <ClayButton>Back to Home</ClayButton>
                </a>
              </ClayLayout.Col>
            </ClayLayout.Row>
          </ClayLayout.Col>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </div>
  );
}
export default PageNotFound;
