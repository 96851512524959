import { DEFAULT_ORDER_BY_ELOQUA, DEFAULT_SORT_BY_ELOQUA } from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { staticStrings } from "@staticStrings";
import { IList } from "common-ui-modules/common-components/DropdownCheckBox/DropDownCheckBoxWithLazyLoading";
import {
  EloquaCriteriaResponse,
  IIntegrationEloquaCardDetails,
  IIntegrationEloquaTableResponse
} from "interfaces/eloqua.interface";

interface EloquaInterface {
  eloquaCardContent: IIntegrationEloquaCardDetails[];
  searchKeyWord: string;
  tableResponse: IIntegrationEloquaTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  ruleResponse: EloquaCriteriaResponse;
  criteriaList: string[];
  criteriaValue: string;
  conditionList: string[];
  conditionValue: string;
  errorMessage: string;
  accountFilterList: string[];
  ruleId: string;
  modalHeader: string;
  modalDescription: string;
  submitButtonText: string;
  modalType: string;
  ruleName: string;
  ruleValue: string;
  valueType: string;
  displayValueFlag: boolean;
  conditionInitialValue: string;
  addAccounts: string[];
  selectedAccounts: IList[];
}

const initialState: EloquaInterface = {
  eloquaCardContent: [],
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_ELOQUA,
  sortByColumn: DEFAULT_SORT_BY_ELOQUA,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  criteriaList: [],
  criteriaValue: staticStrings.commonTexts.select,
  conditionList: [],
  conditionValue: staticStrings.commonTexts.select,
  ruleResponse: {
    "Total Activity": [],
    Accounts: [],
    Connections: [],
    Emails: [],
    Meetings: [],
    Name: [],
    Title: []
  },
  errorMessage: "",
  accountFilterList: [],
  ruleId: "",
  modalHeader: "",
  modalDescription: "",
  submitButtonText: "",
  modalType: "",
  ruleName: "",
  ruleValue: "",
  valueType: "",
  displayValueFlag: false,
  conditionInitialValue: "",
  addAccounts: [],
  selectedAccounts: []
};

const eloquaSlice = createSlice({
  name: "eloqua",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setEloquaCardContent: (state, action) => {
      state.eloquaCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setInitEloqua: () => {
      return initialState;
    },
    setCriteriaList: (state, action) => {
      state.criteriaList = action.payload;
    },
    setCriteriaValue: (state, action) => {
      state.criteriaValue = action.payload;
    },
    setConditionList: (state, action) => {
      state.conditionList = action.payload;
    },
    setConditionValue: (state, action) => {
      state.conditionValue = action.payload;
    },
    setRuleResponse: (state, action) => {
      state.ruleResponse = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setAccountFilterList: (state, action) => {
      state.accountFilterList = action.payload;
    },
    setRuleId: (state, action) => {
      state.ruleId = action.payload;
    },
    setModalHeader: (state, action) => {
      state.modalHeader = action.payload;
    },
    setModalDescription: (state, action) => {
      state.modalDescription = action.payload;
    },
    setSubmitButtonText: (state, action) => {
      state.submitButtonText = action.payload;
    },
    setModalType: (state, action) => {
      state.modalType = action.payload;
    },
    setRuleName: (state, action) => {
      state.ruleName = action.payload;
    },
    setRuleValue: (state, action) => {
      state.ruleValue = action.payload;
    },
    setValueType: (state, action) => {
      state.valueType = action.payload;
    },
    setDisplayValueFlag: (state, action) => {
      state.displayValueFlag = action.payload;
    },
    setConditionInitialValue: (state, action) => {
      state.conditionInitialValue = action.payload;
    },
    setAddAccounts: (state, action) => {
      state.addAccounts = action.payload;
    },
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
    }
  }
});

export const {
  setSearchKeyWord,
  setEloquaCardContent,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setInitEloqua,
  setCriteriaList,
  setCriteriaValue,
  setConditionList,
  setConditionValue,
  setRuleResponse,
  setErrorMessage,
  setAccountFilterList,
  setRuleId,
  setModalHeader,
  setModalDescription,
  setSubmitButtonText,
  setModalType,
  setRuleName,
  setRuleValue,
  setValueType,
  setDisplayValueFlag,
  setConditionInitialValue,
  setAddAccounts,
  setSelectedAccounts
} = eloquaSlice.actions;
export default eloquaSlice.reducer;
