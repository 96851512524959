import styles from "./DomainsTable.module.scss";
import {
  defaultSort,
  predefinedValues
} from "@pages/filterIngestion/Tabs/Domains/DomainsTableConstant";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setOrderBy,
  setSortByColumn,
  setSelectedPage
} from "../../store/filterIngestion/domains/domains.slice";
import { Sorting } from "@clayui/core/lib/table/context";
import { RootState } from "store/configureStore";
import { Button, Cell, Row } from "@clayui/core";
import { SingleValueCell, TableList } from "@common-components";
import { PaginationBar } from "../../common-ui-modules/common-components/PaginationBar/PaginationBar";
import {
  IIngestionDomainsTable,
  IIngestionDomainsTableData,
  ITableHeader
} from "interfaces/domains.interface";
import { staticIds } from "@staticIds";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ActionsThreeDot from "@images/ActionsThreeDots.svg";
import { staticStrings } from "@staticStrings";
import { ROUTE_PATHS } from "@constants";
import { useNavigate } from "react-router-dom";
import {
  setCheckedDomainIds,
  setDomainDetailsAccountTitle,
  setDomainDetailsCardContent,
  setSelectedAccountId,
  setSelectedPage as setSelectedPageDomainDetails
} from "@store/filterIngestion/domain-details/domaindetails.slice";
interface DomainsTableProps {
  tableHeader: ITableHeader[];
  isLoading?: boolean;
}

const DomainsTable: React.FC<DomainsTableProps> = ({
  tableHeader,
  isLoading
}) => {
  const [sort, setSort] = useState<Sorting | null>(defaultSort);
  const displayContent = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tableResponse } = useSelector((state: RootState) => state.domains);

  const onSortChange = (sort: Sorting | null) => {
    if (sort) {
      dispatch(setOrderBy(sort.direction));
      dispatch(setSortByColumn(sort.column));
    }
    setSort(sort);
  };

  const renderPaginationBar = (items: IIngestionDomainsTable) => {
    return (
      <PaginationBar
        pageSize={predefinedValues.pageSize}
        setSelectedPage={setSelectedPage}
        currentPageNumber={tableResponse?.response.page_no}
        dataExistFlag={items?.data && items?.data.length > 0}
        totalItems={items?.total}
        paginationBarid="pagination_bar_ingestion_domains"
      />
    );
  };
  const handleDomainDetailsRoute = (id: string) => {
    sessionStorage.removeItem("accountsTab");
    dispatch(setDomainDetailsCardContent([]));
    dispatch(setSelectedPage(1));
    navigate(`${ROUTE_PATHS.DOMAIN_DETAILS_INGESTION_DOMAINS}?party-id=${id}`);
  };
  return (
    <div
      className={styles.tableContainer}
      id={staticIds.ingestion.domains.domainsTable}
    >
      <TableList
        onSortChange={onSortChange}
        sort={sort}
        header={tableHeader}
        isTableLoading={isLoading}
      >
        {tableResponse?.response.data &&
          tableResponse?.response?.data?.map(
            (row: IIngestionDomainsTableData, index: number) => {
              return (
                <Row
                  key={row.account_id}
                  className={styles.tableContainerTableBodyRow}
                >
                  <SingleValueCell
                    cellValue={`${row["account_name"]} - ${row["party_id"]} `}
                  />

                  <SingleValueCell cellValue={row["sales_area"]} />
                  <SingleValueCell cellValue={row["sales_org"]} />

                  <SingleValueCell cellValue={row["sales_region"]} />
                  <SingleValueCell cellValue={row["no_of_domains"]} />
                  {!displayContent && (
                    <Cell>
                      {
                        <DropDown
                          className={styles.dropDown}
                          trigger={
                            <Button
                              className={styles.dropDownBtn}
                              displayType="unstyled"
                              id={staticIds.ingestion.domains.actionButton}
                            >
                              <div className={styles.dropDownText}>
                                {" "}
                                <img src={ActionsThreeDot} alt="edit" />
                              </div>
                            </Button>
                          }
                        >
                          {
                            <DropDown.ItemList className={styles.dropDownItem}>
                              <DropDown.Item
                                key={"1"}
                                onClick={(e: any) => {
                                  dispatch(setSelectedAccountId(row.party_id));
                                  dispatch(setSelectedPageDomainDetails(1));
                                  dispatch(setCheckedDomainIds([]));
                                  dispatch(
                                    setDomainDetailsAccountTitle(
                                      `${row["account_name"]} - ${row["party_id"]} `
                                    )
                                  );
                                  handleDomainDetailsRoute(row.party_id);
                                }}
                                active={true}
                                id={
                                  staticIds.ingestion.domains
                                    .viewDetailsActionButton
                                }
                                style={{ paddingTop: "0.62rem" }}
                              >
                                {
                                  staticStrings.ingestion.domains
                                    .viewDetailsAction
                                }
                              </DropDown.Item>
                            </DropDown.ItemList>
                          }
                        </DropDown>
                      }
                    </Cell>
                  )}
                </Row>
              );
            }
          )}
      </TableList>
      {tableResponse.response &&
        !isLoading &&
        renderPaginationBar(tableResponse.response)}
    </div>
  );
};

export default DomainsTable;
