import { IIngestionAccountsCardDetails } from "interfaces/ingestionAccounts.interface";
import styles from "./IngestionAccountsHeader.module.scss";
import SummaryBox from "@components/SummaryBox/SummaryBox";
import { staticIds } from "@staticIds";
import SearchInput from "@components/SearchInput/SearchInput";
import { staticStrings } from "@staticStrings";
import { useCallback, useState } from "react";
import { debounce } from "@utils";
import { RootState } from "@store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsActive,
  setSelectedPage
} from "@store/ingestionAccounts/ingestionAccounts.slice";

interface IngestionAccountsHeaderProps {
  setSearchKeyWord: (keyWord: string) => void;
}

const IngestionAccountHeader: React.FC<IngestionAccountsHeaderProps> = ({
  setSearchKeyWord
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [clickedCard, setClickedCard] = useState<number>(0);
  const dispatch = useDispatch();

  const { ingestionAccountsCardContent } = useSelector(
    (state: RootState) => state.ingestionAccounts
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce(setSearchKeyWord, 500),
    [setSearchKeyWord]
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };

  const handleCardClick = (index: number) => {
    setClickedCard(index);
    dispatch(setSelectedPage(1));
    switch (index) {
      case 0:
        return dispatch(setIsActive(""));
      case 1:
        return dispatch(setIsActive(staticStrings.ingestion.accounts.active));
      case 2:
        return dispatch(setIsActive(staticStrings.ingestion.accounts.inactive));
      default:
        return dispatch(setIsActive(""));
    }
  };

  return (
    <div className={styles.accountCardAndSearchSection}>
      <div className={styles.accountCardOuterContainer}>
        {ingestionAccountsCardContent?.map(
          (data: IIngestionAccountsCardDetails, index: number) => {
            const isActive = clickedCard === index;

            return (
              <div
                className={
                  isActive ? styles.accountCardActive : styles.accountCard
                }
                key={data.id}
                onClick={() => handleCardClick(index)}
              >
                <div className={styles.accountCardExtraPadding}>
                  <SummaryBox
                    headerText={data.headerText}
                    bodyText={data.bodyText}
                    image={data.image}
                    summaryCardId={`${staticIds.ingestion.accounts.card}${index}`}
                  />
                </div>
              </div>
            );
          }
        )}
      </div>
      <div className={styles.searchAccountContainer}>
        <SearchInput
          onChange={handleSearch}
          searchValue={searchInput}
          placeholder={staticStrings.ingestion.accounts.searchPlaceholder}
        />
      </div>
    </div>
  );
};

export default IngestionAccountHeader;
