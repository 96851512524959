import React from "react";
import LoadingGif from "@images/loading-circle-black.gif";
import styles from "./ContainerLoader.module.scss";
import { staticStrings } from "@staticStrings";

interface ContainerLoaderProps {
  msgRequired?: boolean;
}
function ContainerLoader({ msgRequired = true }: ContainerLoaderProps) {
  return (
    <div className={styles.containerLoaderWrapper}>
      <img
        className={styles.containerLoaderIcon}
        src={LoadingGif}
        alt={"Loading ..."}
      />
      {msgRequired && <p>{staticStrings.fetchDataMsg}</p>}
    </div>
  );
}

export { ContainerLoader };
