import { IPeopleReportingTableResponse } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_API_INIT_STATE,
  DEFAULT_ORDER_BY_PEOPLE_REPORTING,
  DEFAULT_SORT_BY_PEOPLE_REPORTING
} from "../../../utils/constants/reducerConstants";
interface AccountReportInterface {
  orderByPeopleReporting: string;
  sortByColumnPeopleReporting: string;
  tableResponsePeopleReporting: IPeopleReportingTableResponse | {};
  selectedPagePeopleReporting: number;
  selectedDivisions: string[];
  searchKeyWord: string;
  accountFilterList: string[];
}
const initialState: AccountReportInterface = {
  orderByPeopleReporting: DEFAULT_ORDER_BY_PEOPLE_REPORTING,
  sortByColumnPeopleReporting: DEFAULT_SORT_BY_PEOPLE_REPORTING,
  tableResponsePeopleReporting: DEFAULT_API_INIT_STATE,
  selectedPagePeopleReporting: 1,
  selectedDivisions: [],
  accountFilterList: [],
  searchKeyWord: ""
};
const accountReportSlice = createSlice({
  name: "peopleReport",
  initialState,
  reducers: {
    setOrderByPeopleReporting: (state, action) => {
      state.orderByPeopleReporting = action.payload;
    },
    setSortByColumnPeopleReporting: (state, action) => {
      state.sortByColumnPeopleReporting = action.payload;
    },
    setTableResponsePeopleReporting: (state, action) => {
      state.tableResponsePeopleReporting = action.payload;
    },
    setSelectedPagePeopleReporting: (state, action) => {
      state.selectedPagePeopleReporting = action.payload;
    },
    setSelectedDivisions: (state, action) => {
      state.selectedDivisions = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setAccountFilterList: (state, action) => {
      state.accountFilterList = action.payload;
    },
    setAccountFilterListAndPageNumber: (state, action) => {
      state.selectedPagePeopleReporting = 1;
      state.accountFilterList = action.payload;
    },
    setInitPeopleReporting: () => {
      return initialState;
    }
  }
});
export const {
  setOrderByPeopleReporting,
  setSortByColumnPeopleReporting,
  setTableResponsePeopleReporting,
  setSelectedPagePeopleReporting,
  setSelectedDivisions,
  setInitPeopleReporting,
  setAccountFilterList,
  setAccountFilterListAndPageNumber,
  setSearchKeyWord
} = accountReportSlice.actions;
export default accountReportSlice.reducer;
