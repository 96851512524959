import { ROUTE_PATHS } from "@constants";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const getEnvVars = (state: any) => state.initializeApp.envVars;

export default function Logout() {
  const envVars = useSelector(getEnvVars);
  const { oktaAuth } = useOktaAuth() || {};

  const logout = async () => {
    if (envVars.disableOkta) {
      localStorage.setItem("email", "");
      window.location.href = ROUTE_PATHS.HOME;
    } else {
      try {
        localStorage.clear();
        sessionStorage.clear();
        await oktaAuth.signOut();
      } catch (err: any) {
        console.log("err: ", err);
        window.location.href = ROUTE_PATHS.HOME;
      }
    }
  };

  useEffect(() => {
    logout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>Logging out ...</>;
}
