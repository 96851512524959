import { createSlice } from "@reduxjs/toolkit";

import {
  DEFAULT_ORDER_BY_CUSTOM_TEAMS,
  DEFAULT_SORT_BY_CUSTOM_TEAMS
} from "../../../utils/constants/reducerConstants";
import { ICustomTeamTableResponse } from "interfaces/userManagement.interface";

interface ICustomTeamInterface {
  tableResponse: ICustomTeamTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  searchKeyWord: string;
}

const initialState: ICustomTeamInterface = {
  orderBy: DEFAULT_ORDER_BY_CUSTOM_TEAMS,
  sortByColumn: DEFAULT_SORT_BY_CUSTOM_TEAMS,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  searchKeyWord: ""
};

const customTeamSlice = createSlice({
  name: "customTeam",
  initialState,
  reducers: {
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setInitCustomTeam: () => {
      return initialState;
    }
  }
});

export const {
  setTableResponse,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setSearchKeyWord,
  setInitCustomTeam
} = customTeamSlice.actions;
export default customTeamSlice.reducer;
