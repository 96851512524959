import { Sorting } from "@clayui/core/lib/table/context";
import {
  DEFAULT_ORDER_BY_INGESTION_ACCOUNTS,
  DEFAULT_SORT_BY_INGESTION_ACCOUNTS
} from "../../../../utils/constants/reducerConstants";
import { ITableHeader } from "interfaces/filterIngestion.interface";

export const tableHeader: ITableHeader[] = [
  {
    id: "name",
    name: "Account",
    sortable: true
  },
  {
    id: "no_of_domains",
    name: "No. of Domains",
    sortable: true
  },
  {
    id: "total_active_members",
    name: "Members",
    sortable: true
  },
  {
    id: "classification",
    name: "Classification",
    sortable: true
  },
  {
    id: "updated_at",
    name: "Updated Date",
    sortable: true
  },
  {
    id: "comments",
    name: "Comments",
    sortable: false
  },
  {
    id: "is_active",
    name: "Status",
    sortable: true
  },
  {
    id: "action",
    name: "Actions",
    sortable: false
  }
];

export const predefinedValues = {
  pageSize: 10
};

export const defaultSort: Sorting = {
  column: DEFAULT_SORT_BY_INGESTION_ACCOUNTS,
  direction: DEFAULT_ORDER_BY_INGESTION_ACCOUNTS
};
