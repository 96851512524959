import { ITableHeader } from "interfaces/filterIngestion.interface";

export const predefinedValues = {
  pageSize: 10
};

export const tableHeader: ITableHeader[] = [
  {
    id: "filter__filter_type",
    name: "Type",
    sortable: true
  },
  {
    id: "filter__filter_field",
    name: "Field",
    sortable: true
  },
  {
    id: "pattern",
    name: "Pattern",
    sortable: true
  },
  {
    id: "last_updated_at",
    name: "Last Updated",
    sortable: true
  },
  {
    id: "actions",
    name: "Actions",
    sortable: false
  }
];
