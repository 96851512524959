export interface IPlaceholderText {
  inlineSearchPlaceholder: string;
  noMatches: string;
}

export interface IHierarchyTreeProps {
  showDropdown: "always" | "initial";
  showPartiallySelected?: boolean;
  inlineSearchInput?: boolean;
  keepTreeOnSearch?: boolean;
  keepChildrenOnSearch?: boolean;
  texts: IPlaceholderText;
  showButton?: boolean;
  dropdownLabel: string;
  handleCheckBoxSelection: (data: IselectedNode[], count: number) => void;
  allOption?: IHierarchyTreeData;
  page: string;
  disabled?: boolean;
  className?: string;
  checkBoxClick?: boolean;
  submitButtonMessage: string;
  dropdownLabelClass?: string;
  setInitialData?: () => void;
  selectAllByDefault?: boolean;
  setPrevSelection: React.Dispatch<React.SetStateAction<IHierarchyTreeData[]>>;
  prevSelection: IHierarchyTreeData[];
  handleClearCheckBoxSelection?: () => void;
  dropdownId: string;
}
export interface IHierarchyTreeData {
  id: number | string;
  label: string;
  value: string;
  checked: boolean | string;
  className?: string;
  expanded?: boolean;
  disabled?: boolean;
  children?: IHierarchyTreeData[];
}
export interface ITreeNode {
  id: number | string;
  label: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  expanded?: boolean;
  className?: string;
  tagClassName?: string;
  isDefaultValue?: boolean;
  [property: string]: any;
}

export interface IselectedNode {
  checked: boolean;
  id: number;
  label: string;
  partial: boolean;
  value: string;
  _depth: number;
  _focused: boolean;
  _id: number;
  _parent: number;
}

export interface IAccountLevel {
  id: string;
  name: string;
}

export interface ISalesOrganization {
  id: string;
  name: string;
  accounts: IAccountLevel[];
}

export interface ISalesArea {
  id: string;
  name: string;
  sales_organizations: ISalesOrganization[];
}

export interface ISalesRegion {
  id: string;
  name: string;
  sales_area: ISalesArea[];
}

export interface ITreeResponse {
  error: null | string;
  response: {
    page_no: number;
    total: number;
    data: ISalesRegion[];
  };
}
export interface ITreeModifiedResponse {
  error: null | string;
  response: {
    page_no: number;
    total: number;
    data: IHierarchyTreeData[];
  };
}

export interface IFiltersState {
  level: string;
  value: string | number;
}

export enum TreeLevels {
  REGION = "sales_region",
  AREA = "sales_area",
  ORG = "sales_organization",
  ACCOUNTS = "account"
}
