import {
  IEngagedInternalTableResponse,
  ISalesRepresentativeSearch
} from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_ORDER_BY_ENGAGED_PEOPLE } from "../../../utils/constants/reducerConstants";

interface EngagedPeopleInterface {
  orderBy: string;
  sortByColumn: string | null;
  salesRepresentative: ISalesRepresentativeSearch[];
  userFilterList: string[];
  tableResponse: IEngagedInternalTableResponse | {};
  selectedPage: number;
}

const initialState: EngagedPeopleInterface = {
  orderBy: DEFAULT_ORDER_BY_ENGAGED_PEOPLE,
  sortByColumn: null,
  userFilterList: [],
  salesRepresentative: [],
  tableResponse: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPage: 1
  // ... other initial settings
};

const engagedPeopleSlice = createSlice({
  name: "engagedPeople",
  initialState,
  reducers: {
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setUserFilterList: (state, action) => {
      state.userFilterList = action.payload;
    },
    setSalesRepresentative: (state, action) => {
      state.salesRepresentative = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setUserFilterListAndPageNumber: (state, action) => {
      state.selectedPage = 1;
      state.userFilterList = action.payload;
    },
    setInitEngagedPeople: () => {
      return initialState;
    }
    // ... other actions
  }
});

export const {
  setOrderBy,
  setSortByColumn,
  setUserFilterList,
  setSalesRepresentative,
  setTableResponse,
  setSelectedPage,
  setUserFilterListAndPageNumber,
  setInitEngagedPeople
} = engagedPeopleSlice.actions;
export default engagedPeopleSlice.reducer;
