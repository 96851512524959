import { ClayInput } from "@clayui/form";
import React from "react";
import searchIcon from "@images/searchIcon.svg";
import closeIcon from "@images/close.svg";
import styles from "./SearchInput.module.scss";
import { staticStrings } from "../../utils/constants/staticStrings";

interface ISearchInputProps {
  searchValue: string;
  onChange: (value: string) => void;
  placeholder?: string;
  alignSearchLeftFlag?: boolean;
  enableCancelButtonFlag?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  onChange,
  searchValue,
  placeholder = staticStrings.account360.detailspage.search,
  alignSearchLeftFlag = true,
  enableCancelButtonFlag = true
}) => {
  return (
    <ClayInput.Group className={styles.searchInputGroup}>
      {alignSearchLeftFlag && (
        <ClayInput.GroupItem shrink>
          <ClayInput.GroupText className={styles.searchIconContainer}>
            <img src={searchIcon} alt="search" />
          </ClayInput.GroupText>
        </ClayInput.GroupItem>
      )}

      <ClayInput.GroupItem className={styles.searchGroupItem}>
        <ClayInput
          placeholder={placeholder}
          type="text"
          value={searchValue}
          className={styles.searchInput}
          onChange={(e) => onChange(e.target.value)}
          name="search_input"
        />
      </ClayInput.GroupItem>
      {enableCancelButtonFlag && searchValue && (
        <ClayInput.GroupItem shrink className={styles.searchGroupItem}>
          <ClayInput.GroupText
            className={styles.closeIconContainer}
            onClick={() => onChange("")}
          >
            <img src={closeIcon} alt="close" className={styles.loaderIcon} />
          </ClayInput.GroupText>
        </ClayInput.GroupItem>
      )}
      {!alignSearchLeftFlag && (
        <ClayInput.GroupItem shrink>
          <ClayInput.GroupText className={styles.searchIconContainer}>
            <img src={searchIcon} alt="search" />
          </ClayInput.GroupText>
        </ClayInput.GroupItem>
      )}
    </ClayInput.Group>
  );
};

export default SearchInput;
