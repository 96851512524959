import { IAccountTableResponse } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING,
  DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING
} from "../../../utils/constants/reducerConstants";

interface CustomTeamReportingInterface {
  orderByCustomTeamReporting: string;
  sortByColumnCustomTeamReporting: string;
  userFilterListCustomTeamReporting: string[];
  tableResponseCustomTeamReporting: IAccountTableResponse | {};
  selectedPageCustomTeamReporting: number;
}

const initialState: CustomTeamReportingInterface = {
  orderByCustomTeamReporting: DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING,
  sortByColumnCustomTeamReporting: DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING,
  userFilterListCustomTeamReporting: [],
  tableResponseCustomTeamReporting: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPageCustomTeamReporting: 1
};

const customTeamReporting = createSlice({
  name: "customTeamReporting",
  initialState,
  reducers: {
    setOrderByCustomTeamReporting: (state, action) => {
      state.orderByCustomTeamReporting = action.payload;
    },
    setSortByColumnCustomTeamReporting: (state, action) => {
      state.sortByColumnCustomTeamReporting = action.payload;
    },
    setUserFilterListCustomTeamReporting: (state, action) => {
      state.userFilterListCustomTeamReporting = action.payload;
    },
    setTableResponseCustomTeamReporting: (state, action) => {
      state.tableResponseCustomTeamReporting = action.payload;
    },
    setSelectedPageCustomTeamReporting: (state, action) => {
      state.selectedPageCustomTeamReporting = action.payload;
    },
    setInitCustomTeamReporting: () => {
      return initialState;
    }
  }
});

export const {
  setOrderByCustomTeamReporting,
  setSortByColumnCustomTeamReporting,
  setUserFilterListCustomTeamReporting,
  setTableResponseCustomTeamReporting,
  setSelectedPageCustomTeamReporting,
  setInitCustomTeamReporting
} = customTeamReporting.actions;
export default customTeamReporting.reducer;
