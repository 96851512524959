import { IUser } from "@interfaces";
export interface IAttendeeData {
  response: string;
  attendee: IUser;
}

export interface IEventDetails {
  title: string;
  start_date_time: string;
  end_date_time?: string;
  attendees: IAttendeeData[];
  id: string;
  organiser?: IIndividualUserData;
}
interface IEventsData {
  total_time_spent_in_minutes: number;
  events: IEventDetails[];
}

interface IMeetingResponse {
  page_no: number;
  total: number;
  data: IEventsData;
}

export interface ImeetingData {
  error?: string | null;
  response?: IMeetingResponse;
}
export interface IIndividualUserData {
  name: string;
  title: string;
  email_address: string;
  account?: string;
  profile_picture?: string;
  is_active: boolean;
}
export interface IUsersObjectList {
  [key: string]: IIndividualUserData[];
}

export enum MeetingStatus {
  ACCEPTED = "accepted",
  DECLINED = "declined",
  TENTATIVE = "tentative",
  NEEDS_ACTION = "needsAction"
}
