import { configureStore as configStore } from "@reduxjs/toolkit";

import { globalAlertHandlerReducer } from "./slice/globalAlertHandler.slice";
import { initializeAppReducer } from "./slice/initializeApp.slice";

export const configureStore: any = (configureOptions: any) => {
  const store = configStore({
    reducer: {
      ...configureOptions.reducer,
      globalAlertHandler: globalAlertHandlerReducer,
      initializeApp: initializeAppReducer
    }
  });

  return store;
};
