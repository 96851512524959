// summarySlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { staticStrings } from "@staticStrings";

const initialState = {
  selectedRangeReporting: staticStrings.reporting.range90Days,
  activeTabReporting: 0
  // ... other initial settings
};

const reporting = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    setSelectedRangeReporting: (state, action) => {
      state.selectedRangeReporting = action.payload;
    },
    setActiveTabReporting: (state, action) => {
      state.activeTabReporting = action.payload;
    }
  }
});

export const { setSelectedRangeReporting, setActiveTabReporting } =
  reporting.actions;
export default reporting.reducer;
