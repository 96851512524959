import React from "react";
import Text from "./Controls/Text";
import Dropdown from "./Controls/DropDown";
import SearchWithLoader from "./Controls/SearchWithLoader";
import TextArea from "./Controls/TextArea";

interface IFormControlProps {
  control: string;
  name: string;
  label: string;
  placeholder: string;
  filteredList?: string[];
  disabled?: boolean;
  handleNameChange?: (event: any) => void;
  isLoading?: boolean;
}
const FormControl: React.FC<IFormControlProps> = ({
  control,
  name,
  label,
  placeholder,
  filteredList,
  disabled,
  isLoading
}) => {
  switch (control) {
    case "text":
      return (
        <Text
          name={name}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
        />
      );
    case "dropdown":
      return filteredList && filteredList.length > 0 ? (
        <Dropdown
          name={name}
          label={label}
          placeholder={placeholder}
          filteredList={filteredList}
          disabled={disabled}
        />
      ) : (
        <></>
      );
    case "searchwithloader":
      return (
        <SearchWithLoader
          name={name}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          isLoading={
            isLoading === undefined || isLoading === null ? false : isLoading
          }
        />
      );
    case "textarea":
      return (
        <TextArea
          name={name}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
        />
      );

    default:
      return null;
  }
};

export { FormControl };
