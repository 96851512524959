import React from "react";
import headerLogo from "@images/company-logo.svg";
import styles from "./Header.module.scss";
import { staticStrings } from "@staticStrings";

const Header = () => {
  const { title } = staticStrings.headers;
  return (
    <React.Fragment>
      <header className="brdcm-standard-header brdcm-fade-in">
        <div className={styles.brdcmHeader}>
          <div className={styles.titleLogoWrapper}>
            <img
              src={headerLogo}
              className={styles.headerLogo}
              alt="company-logo"
            />
            <span className={styles.title}> {title} </span>
          </div>
        </div>
        <div className={`${styles.headerBorder}`}></div>
      </header>
    </React.Fragment>
  );
};

export { Header };
