import { GlobalAlertHandler, GlobalAPILoader } from "@common-components";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import Routes from "./Routes/Routes";
import { RootState } from "store/configureStore";
import { Suspense, useEffect, useState } from "react";
import { PATHS } from "@constants";
import { setUserPermissions } from "./store/config/config.slice";
import { axiosHttp, getIsOktaAuth } from "@common-services";

function App() {
  const dispatch = useDispatch();
  const { userPermissions } = useSelector((state: RootState) => state.config);
  const [isLoading, setIsLoading] = useState(false);
  const isOktaAuthenticated = useSelector(getIsOktaAuth);

  useEffect(() => {
    if (!userPermissions.length && isOktaAuthenticated) {
      setIsLoading(true);
      axiosHttp
        .get(PATHS.USER_CONFIG.USER_PERMISSIONS)
        .then((response) => {
          dispatch(setUserPermissions(response.data?.response));
          setIsLoading(false);
        })
        .catch((error: any) => {
          setIsLoading(false);
        });
    }
  }, [dispatch, userPermissions.length, isOktaAuthenticated]);
  return (
    <>
      {isLoading && <GlobalAPILoader />}
      {!isLoading && (
        <Suspense fallback={<GlobalAPILoader />}>
          <div className="app-container">
            <GlobalAlertHandler />
            <Routes />
          </div>
        </Suspense>
      )}
    </>
  );
}

export default App;
