import { useEffect, useState } from "react";
import styles from "./SalesAlert.module.scss";
import closeIcon from "@images/x-gray.svg";
import { ALERT_TYPES } from "@common-interfaces";
import { ReactComponent as TickIcon } from "@images/tick-icon-success-green.svg";
import { ReactComponent as ErrorIcon } from "@images/error-icon.svg";

interface IAlertProps {
  setAlertOpen: (status: boolean) => void;
  message: string;
  type?: ALERT_TYPES;
}

const SalesAlert: React.FC<IAlertProps> = ({
  setAlertOpen,
  message,
  type = ALERT_TYPES.SUCCESS
}) => {
  const [customStyle, setCustomStyle] = useState(styles.success);
  useEffect(() => {
    if (type === ALERT_TYPES.ERROR) setCustomStyle(styles.error);
    if (type === ALERT_TYPES.INFO) setCustomStyle(styles.info);
  }, [type]);

  return (
    <div className={styles.statusAlert + " " + customStyle}>
      <div className={styles.alertContent}>
        <div className={styles.alertIcon}>
          {type === ALERT_TYPES.ERROR && <ErrorIcon />}
          {type === ALERT_TYPES.SUCCESS && <TickIcon />}
        </div>
        <div className={styles.filterIngestionInfoContainerText}>{message}</div>
      </div>
      <div>
        <img
          src={closeIcon}
          alt="close"
          className={styles.alertClose}
          onClick={() => setAlertOpen(false)}
          onKeyUp={() => setAlertOpen(false)}
        />
      </div>
    </div>
  );
};
export { SalesAlert };
