import { useSelector } from "react-redux";
import { RootState } from "store/configureStore";

export { HelperFunctions } from "./HelperFunctions";

export const checkIfAllowed = (
  allowedFunctions: any,
  userFunctions: any,
  allowedBUs?: any,
  selectedDivision?: string,
  elligibleDivision?: string[]
) => {
  if (
    allowedBUs &&
    allowedBUs.length &&
    selectedDivision &&
    !~allowedBUs.indexOf(selectedDivision)
  ) {
    return false;
  }

  let isAllowed = true;
  if (allowedFunctions && allowedFunctions.length) {
    isAllowed = allowedFunctions.some((permission: string) =>
      userFunctions.includes(permission)
    );
  }
  return isAllowed;
};

type DebouncedFunction<F extends (...args: any[]) => any> = (
  ...args: Parameters<F>
) => void;

export function debounce<F extends (...args: any[]) => any>(
  func: F,
  delay: number
): DebouncedFunction<F> {
  let timeoutId: NodeJS.Timeout;

  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export const isHomePath = (path: string) => path === "/home";

//UsePermission Hook
export const usePermission = (
  requiredPermission?: string,
  omitChecking?: boolean
): boolean => {
  const userPermissions = useSelector(
    (state: RootState) => state.config.userPermissions
  );
  if (omitChecking) return true;
  const hasPermission = userPermissions.includes(requiredPermission);
  return hasPermission;
};

const OKTATOKENSTORAGEKEY = "okta-token-storage";

export function GET_USER_EMAIL() {
  const oktaToken = JSON.parse(
    localStorage.getItem(OKTATOKENSTORAGEKEY) || "{}"
  );
  return oktaToken?.idToken?.claims?.email || "";
}

export function GET_USER_NAME_OKTA() {
  const oktaToken = JSON.parse(
    localStorage.getItem(OKTATOKENSTORAGEKEY) || "{}"
  );
  return oktaToken?.idToken?.claims?.name || "";
}

export function GET_USER_EMAIL_OKTA() {
  const oktaToken = JSON.parse(
    localStorage.getItem(OKTATOKENSTORAGEKEY) || "{}"
  );
  return oktaToken?.idToken?.claims?.email.toLowerCase() || "";
}

export function getAuthToken() {
  const oktaStorage = sessionStorage.getItem(OKTATOKENSTORAGEKEY) || "{}";
  return JSON.parse(oktaStorage)?.accessToken?.accessToken || "";
}
