import { createSlice } from "@reduxjs/toolkit";

interface AccountReportInterface {
  userPermissions?: string[];
}

const initialState: AccountReportInterface = {
  userPermissions: []
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    }
  }
});

export const { setUserPermissions } = configSlice.actions;
export default configSlice.reducer;
