const CrossIcon = function (props: any) {
  const { color, height = " 24", width = " 24" } = props;

  interface IStrokeObject {
    [key: string]: any;
  }

  const strokeColor: IStrokeObject = {
    gray: "#4F575E",
    black: "#101213",
    red: "#cc092f",
    blue: "#005C8A"
  };

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke={strokeColor[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={strokeColor[color]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CrossIcon };
