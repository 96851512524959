import { ComponentType } from "react";

type ImportComponent = () => Promise<{ default: ComponentType }>;

export const lazyRetry = (componentImport: ImportComponent) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, "false");
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, "true");
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, "true");
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
