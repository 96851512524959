import { useEffect, useState } from "react";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayIcon from "@clayui/icon";
import spritemap from "@images/icons.svg";
import ClayButton from "@clayui/button";
import style from "./DropdownTreeView.module.scss";
import { staticStrings } from "@staticStrings";
import DropdownTreeSelect from "react-dropdown-tree-select";
import {
  IHierarchyTreeData,
  IHierarchyTreeProps,
  ITreeNode,
  IselectedNode
} from "@interfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/configureStore";
import "react-dropdown-tree-select/dist/styles.css";
import { setHeirarchyTreeResponseLoader } from "../../../store/reporting/account/accountReport.slice";
import "./DropdownTree.scss";
import { ContainerLoader } from "../container-loader/ContainerLoader";
import { HelperFunctions } from "@utils";
import CustomButton from "../CustomButton/CustomButton";

const DropdownTreeView = ({
  showDropdown,
  showPartiallySelected = true,
  inlineSearchInput = true,
  keepTreeOnSearch = true,
  keepChildrenOnSearch = true,
  texts,
  showButton = true,
  dropdownLabel,
  handleCheckBoxSelection,
  allOption,
  disabled = false,
  submitButtonMessage,
  className,
  dropdownLabelClass,
  setPrevSelection,
  prevSelection,
  handleClearCheckBoxSelection,
  dropdownId
}: IHierarchyTreeProps) => {
  let updatedNodes: IselectedNode[] = [];
  const [open, setOpen] = useState(false);
  const { clear } = staticStrings.hierarchyTreeDropdown;
  const dispatch = useDispatch();
  const treeData = useSelector(
    (state: RootState) =>
      state.accountReport.hierarchyTreeResponse?.response?.data
  );
  const { heirarchyTreeResponseLoader } = useSelector(
    (state: RootState) => state.accountReport
  );
  const initialData =
    allOption && Object.keys(allOption).length !== 0
      ? [{ ...allOption, children: JSON.parse(JSON.stringify(treeData)) }]
      : JSON.parse(JSON.stringify(treeData));

  const [dropdownRenderkey, setDropdownRenderkey] = useState(0);
  const [data, setData] = useState<IHierarchyTreeData[]>([]);
  const [clearSelect, setClearSelect] = useState<boolean>(false);
  const searchText = document.getElementsByClassName(
    "search"
  )[0] as HTMLInputElement;

  useEffect(() => {
    if (treeData && treeData.length > 0) {
      dispatch(setHeirarchyTreeResponseLoader(false));
      const copyData = JSON.parse(JSON.stringify(initialData));
      setData(copyData);
      setPrevSelection(copyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const updateChildren = (nodes: IHierarchyTreeData[]) => {
    nodes.forEach((node) => {
      node.checked = true;
      if (node.children) {
        updateChildren(node.children);
      }
    });
  };

  const updateData = (
    nodes: IHierarchyTreeData[],
    updatedNodes: ITreeNode[]
  ) => {
    return nodes.map((node) => {
      const matchingNode =
        updatedNodes &&
        updatedNodes.length > 0 &&
        updatedNodes.find(
          (updatedNode: ITreeNode) => updatedNode.id === node.id
        );
      if (matchingNode) {
        node.checked = matchingNode.checked || false;
        if (node.children) updateChildren(node.children);
      }
      if (node.children) {
        node.children = updateData(node.children, updatedNodes);
      }
      return node;
    });
  };
  const handleClearSearch = () => {
    if (searchText?.value) {
      searchText.value = "";
    }
    setDropdownRenderkey(dropdownRenderkey + 1);
  };
  const handleSubmit = () => {
    if (clearSelect && updatedNodes.length === 0) {
      if (handleClearCheckBoxSelection) handleClearCheckBoxSelection();
      setPrevSelection(data);
    } else if (updatedNodes.length > 0) {
      const selectedNodes = updateData(initialData, updatedNodes);
      const count = HelperFunctions.countCheckedLeafNodes(selectedNodes);
      handleCheckBoxSelection(updatedNodes, count);
      setData(selectedNodes);
      setPrevSelection(selectedNodes);
    }
    setClearSelect(false);
    handleClearSearch();
    setOpen(!open);
  };
  const handleOnChange = (currentNode: any, selectedNodes: any) => {
    updatedNodes = selectedNodes;
    if (
      (currentNode.id === 1 &&
        currentNode.label === "All" &&
        !currentNode.checked) ||
      (selectedNodes.length === 0 && currentNode.id !== 1)
    ) {
      handleClearSelection();
    }
  };
  const handleClearSelection = () => {
    updatedNodes = [];
    const initializedData = HelperFunctions.setInitialValues(initialData);
    setData(initializedData);
    handleClearSearch();
    setClearSelect(true);
  };
  return (
    <DropDown
      active={open}
      onActiveChange={(value: boolean) => {
        if (!value) {
          setData(prevSelection);
        }
        setClearSelect(false);
        handleClearSearch();
        setOpen(value);
      }}
      className={style.treeDropDown}
      trigger={
        <ClayButton
          displayType="unstyled"
          className={style.treeDropDownBtn}
          onClick={() => {
            setData(prevSelection);
            setClearSelect(false);
            handleClearSearch();
            setOpen(!open);
          }}
          disabled={disabled}
          id={dropdownId}
        >
          <div
            className={`${dropdownLabelClass ? dropdownLabelClass : ""} ${
              style.label
            }`}
          >
            {dropdownLabel}
          </div>
          {!open ? (
            <ClayIcon symbol="angle-down" spritemap={spritemap} />
          ) : (
            <ClayIcon symbol="angle-up" spritemap={spritemap} />
          )}
        </ClayButton>
      }
    >
      {heirarchyTreeResponseLoader && <ContainerLoader msgRequired={false} />}
      {!heirarchyTreeResponseLoader && (
        <div className={style.treeContainer}>
          <DropDown.Section>
            <div
              className={`${style.treeOuterWrap} ${className ? className : ""}`}
            >
              <div className={style.treeInnerWrapUserManagement}>
                <DropdownTreeSelect
                  key={dropdownRenderkey}
                  data={data}
                  showDropdown={showDropdown}
                  showPartiallySelected={showPartiallySelected}
                  inlineSearchInput={inlineSearchInput}
                  keepTreeOnSearch={keepTreeOnSearch}
                  keepChildrenOnSearch={keepChildrenOnSearch}
                  onChange={(currentNode: any, selectedNodes: any) =>
                    handleOnChange(currentNode, selectedNodes)
                  }
                  texts={texts}
                />
              </div>
            </div>
          </DropDown.Section>
          {showButton && (
            <DropDown.Section>
              <div className={style.treeButtonContainer}>
                <ClayButton.Group spaced>
                  <CustomButton
                    displayType="secondary"
                    className={style.clearButton}
                    onClick={handleClearSelection}
                    id={`clear_button_${dropdownId}`}
                  >
                    {clear}
                  </CustomButton>

                  <CustomButton
                    displayType="primary"
                    onClick={handleSubmit}
                    className={style.doneButton}
                    id={`submit_button_${dropdownId}`}
                  >
                    {submitButtonMessage}
                  </CustomButton>
                </ClayButton.Group>
              </div>
            </DropDown.Section>
          )}
        </div>
      )}
    </DropDown>
  );
};

export { DropdownTreeView };
