export const staticIds = {
  account360: {
    card: "card_activitytrends_",
    engagedPeople: "card_account360_engagedpeople_",
    engagedPeopleDropDownList: "dropdown_list_account360_engagedpeople_",
    dropdownList: "dropdown_list_account360_"
  },
  activityTrends: {
    detailsPage: {
      emailTabModal: "modal_activitytrends_details_email_",
      meetingTabModal: "modal_activitytrends_details_meeting_"
    },
    upcomingMeetingsModal: "modal_activitytrends_upcomingmeeting_",
    accountDivisionDropdown: "account_division_dropdown"
  },
  engagementScore: {
    monthRange: "month_range",
    scoreChart: "score_chart",
    curScore: "value_current_score",
    highScore: "value_highest_score",
    lowScore: "value_lowest_score",
    lowRange: "range_low",
    midRange: "range_middle",
    highRange: "range_high"
  },
  daysDropDown: {
    list: "dropdown_list_daysdropdown_"
  },
  filterIngestion: {
    addModal: "modal_filteringestion_addmodal_0",
    deleteModal: "modal_filterIngestion_deletemodal_0"
  },
  reporting: {
    accountsTeamDetailsCard: "card_reporting_accounts_team_details_"
  },
  userManagement: {
    customTeam: {
      teamDetailsCard: "card_usermanagement_customteam_teamdetails_",
      teamDetailsList: "dropdown_list_customteam_teamdetails_addedituser_",
      createCustomTeamModal:
        "modal_usermanagement_customteam_createcustomteam_0",
      deleteModal: "modal_usermanagement_customteam_deletemodal_0",
      teamDetailsAddModal:
        "modal_usermanagement_customteam_teamdetails_adduser_0"
    },
    usersCard: "card_usermanagement_users_",
    users: {
      addModal: "modal_usermanagement_users_addedituser_0",
      ingestionModal: "modal_usermanagement_users_ingestionandaccess_0",
      teamDetailsModal: "modal_usermanagement_users_teamdetails_0"
    },
    accounts: {
      reingest: "reingest_accounts",
      accountsCard: "card_usermanagement_user_accounts_",
      backButton: "button_usermanagement_user_accounts_back",
      deleteModal: "modal_usermanagement_user_accounts_deletemodal_0",
      paginationBar: "pagination_bar_usermanagement_user_accounts",
      table: "table_usermanagement_user_accounts"
    }
  },
  ingestion: {
    domains: {
      accountsCard: "card_ingestion_domains_",
      domainsTable: "table_ingestion_domains",
      viewDetailsActionButton: "dropdown_list_view_details_action",
      actionButton: "action_button_ingestion_domains",
      domainDetailsTable: {
        deleteModal: "modal_ingestion_domaindetails_deletemodal_0",
        domainsCard: "card_ingestion_domains_domaindetails_",
        backButton: "button_ingestion_domains_domaindetails_back",
        paginationBar: "pagination_bar_ingestion_domains_domaindetails",
        table: "table_ingestion_domains_domaindetails",
        actionButton: "action_button_ingestion_domains_domaindetails",
        deleteActionButton: "dropdown_list_delete_action"
      }
    },
    accounts: {
      table: "table_ingestion_accounts",
      card: "card_ingestion_accounts_",
      modal: "ingestion_accounts_status_modal_0"
    }
  },
  integration: {
    eloqua: {
      rulesCard: "card_integration_eloqua_",
      paginationBar: "pagination_bar_eloqua",
      table: "table_eloqua",
      addRuleButton: "add_rule_button",
      addRuleModal: "modal_integration_eloqua_addrulemodal_0",
      addAccountDoneButton: "eloqua_accountdetails_addaccount_done_button",
      addAccountsList: "dropdown_list_addedaccounts_",
      criteriaDropDown: "criteria_dropdown",
      conditionDropDown: "condition_dropdown"
    }
  }
};
