import InfiniteScroll from "react-infinite-scroller";
import LoadingGif from "@images/loading-circle-black.gif";
import styles from "./LazyLoadingContainer.module.scss";

interface ILazyLoadingContainerProps {
  onScrollBottom: () => void;
  showLoader?: boolean;
  children?: React.ReactNode;
  id: string;
}

const LazyLoadingContainer: React.FC<ILazyLoadingContainerProps> = ({
  onScrollBottom,
  showLoader,
  children,
  id
}) => {
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={onScrollBottom}
      hasMore={true}
      useWindow={false}
      getScrollParent={() => document.getElementById(id)}
      loader={
        showLoader ? (
          <div className={styles.loader} key={0}>
            <img src={LoadingGif} alt={"Loading ..."} />
          </div>
        ) : (
          <div key={0} />
        )
      }
    >
      {children}
    </InfiniteScroll>
  );
};
export default LazyLoadingContainer;
