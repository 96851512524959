import { createSlice } from "@reduxjs/toolkit";
import { IShowUserTeamTableResponse } from "interfaces/userManagement.interface";
import {
  DEFAULT_ORDER_BY_SHOW_USERS_CREATE_CUSTOM_TEAM,
  DEFAULT_SORT_BY_SHOW_USERS_CREATE_CUSTOM_TEAM
} from "../../../utils/constants/reducerConstants";

interface IShowUsersInterface {
  tableResponse: IShowUserTeamTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  searchKeyWord: string;
  selectedUsers: string[];
}

const initialState: IShowUsersInterface = {
  orderBy: DEFAULT_ORDER_BY_SHOW_USERS_CREATE_CUSTOM_TEAM,
  sortByColumn: DEFAULT_SORT_BY_SHOW_USERS_CREATE_CUSTOM_TEAM,
  selectedPage: 1,
  selectedUsers: [],
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  searchKeyWord: ""
};

const showUsersSlice = createSlice({
  name: "showUsers",
  initialState,
  reducers: {
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setInitUsers: () => {
      return initialState;
    }
  }
});

export const {
  setTableResponse,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setSearchKeyWord,
  setSelectedUsers,
  setInitUsers
} = showUsersSlice.actions;
export default showUsersSlice.reducer;
