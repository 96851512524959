import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ClayAlert from "@clayui/alert";
import ClayButton from "@clayui/button";
import ClayModal, { useModal } from "@clayui/modal";
import {
  claySpriteImagePath,
  getAccessDeniedConfig,
  getModalAlert,
  getToastMessages,
  removeAccessDeniedModal,
  removeModalAlert,
  removeToastAlert
} from "@common-services";
import { CrossIcon } from "../icons/cross";

function GlobalAlertHandler() {
  const dispatch = useDispatch();
  const toastMessages = useSelector(getToastMessages);
  const permDeniedConfig = useSelector(getAccessDeniedConfig);
  const modalAlertConfig = useSelector(getModalAlert);
  const onToastClose = (id: any) => {
    dispatch(removeToastAlert(id));
  };

  const { observer, onClose } = useModal({
    onClose: () => dispatch(removeAccessDeniedModal(false))
  });
  const { observer: alertModalObserver, onClose: onAlertModalClose } = useModal(
    {
      onClose: () => dispatch(removeModalAlert())
    }
  );
  return (
    <React.Fragment>
      {toastMessages.length ? (
        <ClayAlert.ToastContainer>
          {toastMessages.map((value: any) => (
            <ClayAlert
              displayType={value.type}
              className={value.position + "-alert"}
              autoClose={value.timeout ? value.timeout : ""}
              key={value.id}
              onClose={() => {
                onToastClose(value.id);
              }}
              spritemap={claySpriteImagePath()}
            >
              <span dangerouslySetInnerHTML={{ __html: value.message }}></span>
              {value.additionalInfo && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: "<br/>" + value.additionalInfo
                  }}
                ></span>
              )}
            </ClayAlert>
          ))}
        </ClayAlert.ToastContainer>
      ) : null}
      {permDeniedConfig.show ? (
        <ClayModal observer={observer} size="sm">
          <ClayModal.Header
            withTitle={false}
            className={"h-auto pt-3 border-bottom"}
          >
            <ClayModal.ItemGroup>
              <ClayModal.Item>
                <ClayModal.TitleSection>
                  <ClayModal.Title className="text-danger font-weight-bold">
                    {"Access Denied"}
                  </ClayModal.Title>
                </ClayModal.TitleSection>
              </ClayModal.Item>
            </ClayModal.ItemGroup>
            {permDeniedConfig.clossable && (
              <ClayButton displayType="unstyled" onClick={onClose}>
                <CrossIcon />
              </ClayButton>
            )}
          </ClayModal.Header>
          <ClayModal.Body className="m-2 mb-4">
            <span>This feature has been disabled by your administrator.</span>
          </ClayModal.Body>
        </ClayModal>
      ) : null}
      {modalAlertConfig.message ? (
        <ClayModal observer={alertModalObserver} size="sm">
          <ClayModal.Header
            withTitle={false}
            className={"h-auto pt-3 border-bottom"}
          >
            <ClayModal.ItemGroup>
              <ClayModal.Item>
                <ClayModal.TitleSection>
                  {modalAlertConfig.type === "danger" ? (
                    <ClayModal.Title className="text-danger font-weight-bold">
                      {"Error"}
                    </ClayModal.Title>
                  ) : (
                    <ClayModal.Title className="text-success font-weight-bold">
                      {"Success"}
                    </ClayModal.Title>
                  )}
                </ClayModal.TitleSection>
              </ClayModal.Item>
            </ClayModal.ItemGroup>

            <ClayButton displayType="unstyled" onClick={onAlertModalClose}>
              <CrossIcon />
            </ClayButton>
          </ClayModal.Header>
          <ClayModal.Body className="m-2 mb-4 modalScroll">
            <span
              className="word-wrap"
              dangerouslySetInnerHTML={{ __html: modalAlertConfig.message }}
            />
          </ClayModal.Body>
        </ClayModal>
      ) : null}
    </React.Fragment>
  );
}

export { GlobalAlertHandler };
