// summarySlice.ts
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountId: {},
  endDateFromChart: "",
  startDateFromChart: "",
  defaultEmailCount: 0,
  defaultMeetingCount: 0,
  defaultUpcomingMeetingCount: 0,
  maxNumberOfMeetings: 0,
  maxNumberOfInBoundEmails: 0,
  maxNumberOfOutBoundEmails: 0,
  isChartRowSelected: false,
  userEmail: "",
  userId: ""
  // ... other initial settings
};

const activityTrends = createSlice({
  name: "activityTrends",
  initialState,
  reducers: {
    setStartDateFromChart: (state, action) => {
      state.startDateFromChart = action.payload;
    },
    setEndDateFromChart: (state, action) => {
      state.endDateFromChart = action.payload;
    },
    setDefaultMeetingCount: (state, action) => {
      state.defaultMeetingCount = action.payload;
    },
    setDefaultUpcomingMeetingCount: (state, action) => {
      state.defaultUpcomingMeetingCount = action.payload;
    },
    setDefaultEmailCount: (state, action) => {
      state.defaultEmailCount = action.payload;
    },
    setMaxNumberOfMeetings: (state, action) => {
      state.maxNumberOfMeetings = action.payload;
    },
    setIsChartRowSelected: (state, action) => {
      state.isChartRowSelected = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setMaxNumberOfInBoundEmails: (state, action) => {
      state.maxNumberOfInBoundEmails = action.payload;
    },
    setMaxNumberOfOutBoundEmails: (state, action) => {
      state.maxNumberOfOutBoundEmails = action.payload;
    }
    // ... other actions
  }
});

export const {
  setStartDateFromChart,
  setEndDateFromChart,
  setDefaultEmailCount,
  setDefaultMeetingCount,
  setDefaultUpcomingMeetingCount,
  setMaxNumberOfMeetings,
  setIsChartRowSelected,
  setUserEmail,
  setUserId,
  setMaxNumberOfInBoundEmails,
  setMaxNumberOfOutBoundEmails
} = activityTrends.actions;
export default activityTrends.reducer;
