import React from "react";
import ClayCard from "@clayui/card";
import styles from "./SummaryBox.module.scss";

interface ISummaryBoxProps {
  headerText: string;
  bodyText: string;
  image: string;
  summaryCardId: string;
}

const SummaryBox: React.FC<ISummaryBoxProps> = ({
  headerText,
  bodyText,
  image,
  summaryCardId
}) => {
  return (
    <div className={`${styles.cardDiv} `} id={summaryCardId}>
      <div className={styles.summaryCard}>
        <div className={styles.imageBox}>
          <img
            alt="thumbnail"
            src={image}
            style={{ width: "2rem", height: "2rem" }}
          />
        </div>
        <div className="autofit-col autofit-col-expand autofit-col-gutters">
          <section className={`autofit-section ${styles.textBox}`}>
            <ClayCard.Description
              truncate={false}
              displayType="title"
              className={styles.summaryHead}
            >
              {headerText ?? "---"}
            </ClayCard.Description>
            <ClayCard.Description
              truncate={false}
              displayType="text"
              className={styles.summaryContent}
            >
              {bodyText}
            </ClayCard.Description>
          </section>
        </div>
      </div>
    </div>
  );
};

export default SummaryBox;
