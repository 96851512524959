import ClayDropDown from "@clayui/drop-down";
import ClayForm from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import styles from "./Controls.module.scss";
import claySpriteImagePath from "@images/clay/sprite.svg";
import TextError from "./TextError";
import Button from "@clayui/button";
import ClayIcon from "@clayui/icon";
import DropDown from "@clayui/drop-down/lib/DropDown";
interface IDropdownProps {
  name: string;
  label: string;
  placeholder: string;
  filteredList: string[];
  disabled?: boolean;
}
const Dropdown: React.FC<IDropdownProps> = ({
  name,
  label,
  placeholder,
  filteredList,
  disabled
}) => {
  const [listToDisplay, setListToDisplay] = useState<string[]>([]);
  const [dropDownText, setDropDownText] = useState<string>("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    setListToDisplay(filteredList);
  }, [filteredList]);

  const renderDropDownButtonText = (value: string, placeholder: string) => {
    if (value) {
      return value;
    }

    return placeholder;
  };
  return (
    <React.Fragment>
      <div>
        {label && (
          <label htmlFor={name} className={styles.labelText}>
            {label}
          </label>
        )}

        <Field name={name}>
          {({ form, field }: { form: any; field: any }) => {
            const { value } = field;
            return (
              <ClayForm.Group
                className={`dropdown-checkbox form-group-sm 
                  ${
                    form.errors[name] && form.touched[name] !== undefined
                      ? "has-error"
                      : ""
                  } ${styles.dropDownContainer}`}
              >
                <DropDown
                  className={`${styles.dropDown}`}
                  trigger={
                    <Button
                      displayType="unstyled"
                      onClick={() => setActive(!active)}
                      {...field}
                      className={`${styles.dropDownBtn} ${styles.formButton}`}
                      disabled={disabled}
                      value={value}
                    >
                      <div>{renderDropDownButtonText(value, placeholder)}</div>
                      {!active ? (
                        <ClayIcon
                          symbol="angle-down"
                          spritemap={claySpriteImagePath}
                        />
                      ) : (
                        <ClayIcon
                          symbol="angle-up"
                          spritemap={claySpriteImagePath}
                        />
                      )}
                    </Button>
                  }
                  active={active}
                  onActiveChange={setActive}
                  menuElementAttrs={{ className: "overflow-hidden mw-100" }}
                >
                  <ClayDropDown.ItemList className={styles.dropDownItemList}>
                    {listToDisplay && listToDisplay.length > 0 && (
                      <div className="overflow-auto">
                        <ClayDropDown.ItemList>
                          {active &&
                            listToDisplay.map((item: any, i: number) => (
                              <ClayDropDown.Item
                                id={`dropdown_list_${item}_${i}`}
                                key={item.id}
                                className={
                                  dropDownText === item
                                    ? `${styles.selectedDropdownValue}`
                                    : ""
                                }
                                onClick={() => {
                                  setDropDownText(item);
                                  setActive((prevState) => !prevState);
                                  form.setFieldValue(field.name, item);
                                  if (field.name === "type") {
                                    form.setFieldValue("field", "");
                                  }
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    className={`ml-2 ${styles.dropdownItem}`}
                                  >
                                    <React.Fragment>{item}</React.Fragment>
                                  </div>
                                </div>
                              </ClayDropDown.Item>
                            ))}
                        </ClayDropDown.ItemList>
                      </div>
                    )}
                  </ClayDropDown.ItemList>
                </DropDown>

                {!dropDownText && (
                  <ErrorMessage
                    name={name}
                    component={TextError}
                    className={styles.formError}
                  />
                )}
              </ClayForm.Group>
            );
          }}
        </Field>
      </div>
    </React.Fragment>
  );
};

export default Dropdown;
