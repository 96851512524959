import { apiCallBegan } from "@common-services";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import data from "../../../../../local-env.json";

const CONFIG_URL = "/envConfig/";

const slice = createSlice({
  name: "initializeApp",
  initialState: {
    envVars: {},
    envVarsSet: false,
    division: "",
    userDetails: null,
    oktaAuth: false,
    userDetailsLoading: false,
    quarterDetailsLoading: false,
    userDetailsError: false,
    userRegion: "",
    divisionList: [],
    userRegionsList: [],
    quarterDetails: {
      historicalQuarters: [],
      currentQuarter: {},
      isQuarterClosable: false,
      quartersTillCurrentFY: []
    },
    yearDetails: {
      currentYear: null,
      years: []
    },
    userNotifications: { notifications: [], notificationCount: null },
    showNotificationsBadge: false
  },
  reducers: {
    envVarsListReceived(initializeApp, action) {
      if (action.payload) {
        initializeApp.envVars = setEnvVariables(action.payload);
        initializeApp.envVarsSet = true;
      }
    },
    divisionRecieved: (initializeApp, action) => {
      initializeApp.division = action.payload;
    },
    userDetailsReceived: (initializeApp, action) => {
      initializeApp.userDetailsLoading = false;
      initializeApp.userDetailsError = false;
      initializeApp.userDetails = action.payload;
    },
    setOktaAuth: (initializeApp, action) => {
      initializeApp.oktaAuth = action.payload;
    },
    setUserDetailsLoading: (initializeApp, action) => {
      initializeApp.userDetailsLoading = action.payload;
    },
    setQuarterDetailsLoading: (initializeApp, action) => {
      initializeApp.quarterDetailsLoading = action.payload;
    },
    setUserDetailsError: (initializeApp, action) => {
      initializeApp.userDetailsError = action.payload;
    },
    setErrorDetailsReceived: (initializeApp, action) => {
      initializeApp.userDetailsError = true;
      initializeApp.userDetailsLoading = false;
      initializeApp.quarterDetailsLoading = false;
    },
    userRegionReceived: (initializeApp, action) => {
      initializeApp.userRegion = action.payload;
    },
    divisionListRecieved: (initializeApp, action) => {
      initializeApp.divisionList = action.payload;
    },
    userRegionsListReceived: (initializeApp, action) => {
      initializeApp.userRegionsList = action.payload;
    },
    quarterDetailsReceived: (initializeApp, action) => {
      initializeApp.quarterDetails = action.payload;
    },
    yearDetailsReceived: (initializeApp, action) => {
      initializeApp.quarterDetailsLoading = false;
      initializeApp.yearDetails = action.payload;
    },
    userNotificationsReceived: (initializeApp, action) => {
      initializeApp.userNotifications = action.payload;
    },
    setShowNotificationBadge: (initializeApp, action) => {
      initializeApp.showNotificationsBadge = action.payload;
    }
  }
});

export const {
  envVarsListReceived,
  divisionRecieved,
  userDetailsReceived,
  setOktaAuth,
  setUserDetailsLoading,
  setUserDetailsError,
  setErrorDetailsReceived,
  userRegionReceived,
  divisionListRecieved,
  userRegionsListReceived,
  quarterDetailsReceived,
  yearDetailsReceived,
  userNotificationsReceived,
  setShowNotificationBadge,
  setQuarterDetailsLoading
} = slice.actions;

// Set Variables dynamically
export const setEnvVariables = (vars: any) => {
  return {
    ...vars,
    OKTA_URL: vars.okta.issuerURL,
    OKTA_CLIENT_ID: vars.okta.clientId,
    disableOkta: vars?.DISABLE_OKTA === "true" ? true : false
  };
};

//Fetch ENV variables
export const updateEnvironmentVariables = () => (dispatch: any) => {
  if (process.env.NODE_ENV === "development") {
    return dispatch(envVarsListReceived({ ...data }));
  } else {
    return dispatch(
      apiCallBegan({
        url: CONFIG_URL,
        method: "GET",
        onSuccess: envVarsListReceived.type,
        skipErrorHandling: true
      })
    );
  }
};

// selectors
export const getEnvVars = (state: any) => state.initializeApp.envVars;
export const getEnvVarsSet = (state: any) => state.initializeApp.envVarsSet;
export const getDivision = (state: any) => state.initializeApp.division;
export const getDivisionList = (state: any) => state.initializeApp.divisionList;
export const getLoggedInUserDetails = (state: any) =>
  state.initializeApp.userDetails;
export const getLoggedInUserFunctions = (state: any) =>
  state.initializeApp?.userDetails?.activeFunctions || [];
export const getIsOktaAuth = (state: any) => state.initializeApp.oktaAuth;
export const getUserDetailsLoadingStatus = (state: any) =>
  state.initializeApp.userDetailsLoading;
export const getQuarterDetailsLoadingStatus = (state: any) =>
  state.initializeApp.quarterDetailsLoading;
export const getUserDetailsErrorStatus = (state: any) =>
  state.initializeApp.userDetailsError;
export const getUsersRegionsList = (state: any) =>
  state.initializeApp.userRegionsList;
export const getUserRegion = (state: any) => state.initializeApp.userRegion;
export const getQuarterDetails = (state: any) =>
  state.initializeApp.quarterDetails;
export const getYearDetails = (state: any) => state.initializeApp.yearDetails;
export const getCurrentYear = createSelector(
  getYearDetails,
  (yearDetails) => yearDetails?.currentYear
);
export const getYearDropDown = createSelector(getYearDetails, (yearDetails) => {
  let displayYears = [];
  if (yearDetails?.years) {
    displayYears = yearDetails.years.map((year: number) => ({
      label: year + "",
      value: year + ""
    }));
  }
  return displayYears;
});

export const getCurrentQuarter = createSelector(
  getQuarterDetails,
  (quarterDetails: any) => quarterDetails.currentQuarter
);
export const getCurrentYearQuarter = createSelector(
  getQuarterDetails,
  (quarterDetails: any) =>
    `${quarterDetails.currentQuarter.year}-Q${quarterDetails.currentQuarter.quarter}`
);
export const getUserNotifications = (state: any) =>
  state.initializeApp.userNotifications;
export const getNotificationBadgeStatus = (state: any) =>
  state.initializeApp.showNotificationsBadge;

// reducer
export const initializeAppReducer = slice.reducer;
