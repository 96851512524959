import LoadingGif from "@images/loading.gif";
import React, { Fragment } from "react";
import "./GlobalAPILoader.scss";

function GlobalAPILoader(props: any) {
  return (
    <Fragment>
      <div className="global-loader-wrapper">
        <img
          className="global-loader-icon"
          src={LoadingGif}
          alt={"Loading ..."}
        />
      </div>
    </Fragment>
  );
}

export { GlobalAPILoader };
