import { GlobalAPILoader } from "@common-components";
import { setOktaAuth } from "@common-services";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

export const RequiredAuth: React.FC = () => {
  const dispatch = useDispatch();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      dispatch(setOktaAuth(false));
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    } else if (authState && authState?.isAuthenticated) {
      dispatch(setOktaAuth(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, authState]);

  if (!authState || !authState?.isAuthenticated) {
    return <GlobalAPILoader />;
  }

  return <Outlet />;
};
