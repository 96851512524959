import { createSlice } from "@reduxjs/toolkit";
import { COMMON_ORDER_BY_ACCOUNT_TEAM } from "../../../utils/constants/reducerConstants";
import { IAccountTeamTableResponse } from "interfaces/accountTeam.interface";

interface AccountTeamInterface {
  orderByAccountTeam: string;
  sortByColumnAccountTeam: string | null;
  tableResponseAccountTeam: IAccountTeamTableResponse | {};
  selectedPageAccountTeam: number;
  emailList: [];
}

const initialState: AccountTeamInterface = {
  orderByAccountTeam: COMMON_ORDER_BY_ACCOUNT_TEAM,
  sortByColumnAccountTeam: null,
  tableResponseAccountTeam: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPageAccountTeam: 1,
  emailList: []
  // ... other initial settings
};

const accountTeamSlice = createSlice({
  name: "accountTeam",
  initialState,
  reducers: {
    setOrderByAccountTeam: (state, action) => {
      state.orderByAccountTeam = action.payload;
    },
    setSortByColumnAccountTeam: (state, action) => {
      state.sortByColumnAccountTeam = action.payload;
    },
    setTableResponseAccountTeam: (state, action) => {
      state.tableResponseAccountTeam = action.payload;
    },
    setSelectedPageAccountTeam: (state, action) => {
      state.selectedPageAccountTeam = action.payload;
    },
    setInitAccountTeam: () => {
      return initialState;
    },
    setEmailList: (state, action) => {
      state.emailList = action.payload;
    }
    // ... other actions
  }
});

export const {
  setOrderByAccountTeam,
  setSortByColumnAccountTeam,
  setTableResponseAccountTeam,
  setSelectedPageAccountTeam,
  setInitAccountTeam,
  setEmailList
} = accountTeamSlice.actions;
export default accountTeamSlice.reducer;
