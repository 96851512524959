export interface ISalesRepresentativeSearch {
  id: string;
  name?: string;
  email_address?: string;
  is_checked?: boolean;
  value?: string;
}

export interface IEngagedInternalTableResponse {
  response: IEngagedInternalTableDetails;
}
export interface IEngagedInternalTableDetails {
  error: string | null;
  page_no: number;
  total: number;
  max_duration: number;
  data: IEngagedInternalTableByPage[];
}

export interface IEngagedInternalTableByPage {
  id: string;
  name: string | null;
  email_address: string;
  phone_number: string | null;
  title: string | null;
  last_engaged: string;
  contacts_engaged: number;
  total_emails: number;
  total_email_duration_in_minutes: number;
  total_emails_sent: number;
  total_emails_sent_duration_in_minutes: number;
  total_emails_received: number;
  total_emails_received_duration_in_minutes: number;
  total_meetings: number;
  total_meeting_duration_in_minutes: number;
  total_past_meetings: number;
  past_meeting_duration_in_minutes: number;
  total_upcoming_meetings?: number;
  upcoming_meetings_in_minutes?: number;
  total_activity_in_minutes: number;
  profile_picture?: string;
  is_active: boolean;
  location?: string;
}

export interface IUserInfo {
  name?: string;
  title?: string | null;
  email?: string;
  phone?: string | null;
}
export interface IEngagedExternalConnectionsResponse {
  response: IEngagedExternalConnectionsDetails;
}

export interface IEngagedExternalConnectionsDetails {
  error: string | null;
  page_no: number;
  total: number;
  max_duration: number;
  data: IEngagedExternalConnectionsByPage[];
}
export interface IEngagedExternalConnectionsByPage {
  id: string;
  name: string | null;
  email_address: string;
  title: string | null;
  last_engaged: string;
  total_email_duration_in_minutes: number;
  total_meeting_duration_in_minutes: number;
  total_activity_in_minutes: number;
  profile_picture?: string;
  is_active: boolean;
  location: string;
}

export interface IEngageCardDetails {
  headerText: string;
  bodyText: string;
  image: string;
  id: string;
}

export enum userTypes {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL"
}

export interface IDropDownResponse {
  page_no: number;
  total: number;
  data: ISalesRepresentativeSearch[];
}
export interface IDropDownApiResponse {
  error: string | null;
  response: IDropDownResponse;
}
