import axios from "axios";
import { getAuthToken } from "@utils";
import { showAlert } from "../redux-helper/store/slice/globalAlertHandler.slice";
import { store } from "@store/configureStore";
import { staticStrings } from "@staticStrings";
import { BASEPATH } from "@constants";

export const axiosHttp = axios.create({
  baseURL: `${BASEPATH}`
});

const CreateInterceptor = () => {
  const errorHandler = (message: string, additionalInfo: any) => {
    store.dispatch(
      showAlert({
        message,
        additionalInfo,
        type: "failure"
      })
    );
  };

  axiosHttp.interceptors.request.use(
    (config: any) => {
      const token = getAuthToken();
      return {
        ...config,
        headers: {
          ...(token !== null && { Authorization: `bearer ${token}` }),
          ...config.headers
        }
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosHttp.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        console.error("Response Error:", error.response.status);

        if (error.response.status && error.response.status === 401) {
          errorHandler(error.response.data.error, "");
        } else {
          errorHandler(
            error.response.data.error ??
              staticStrings.notification_messages.something_wrong,
            ""
          );
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
      return Promise.reject(error);
    }
  );
};
CreateInterceptor();
