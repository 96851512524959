import { axiosHttp } from "@common-services";
import IngestionAccountHeader from "@components/IngestionAccountsHeader/IngestionAccountsHeader";
import IngestionAccountsTable from "@components/IngestionAccountsTable/IngestionAccountsTable";
import { PATHS } from "@constants";
import { RootState } from "@store/configureStore";
import {
  setIngestionAccountsCardContent,
  setSearchKeyWord,
  setSelectedPage,
  setTableResponse
} from "@store/ingestionAccounts/ingestionAccounts.slice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { predefinedValues, tableHeader } from "./IngestionAccountsConstants";
import Total from "@images/ingestionAccounts/total.svg";
import Active from "@images/userManagement/UserManagementActive.svg";
import Inactive from "@images/userManagement/UserManagementInactive.svg";
import { staticStrings } from "@staticStrings";

const IngestionAccounts: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());

  const dispatch = useDispatch();
  const { sortByColumn, orderBy, selectedPage, searchKeyWord, isActive } =
    useSelector((state: RootState) => state.ingestionAccounts);
  const ingestionAccountsCardImages = [Total, Active, Inactive];

  const endpoints = PATHS.INGESTION.ACCOUNTS;
  const getUrlParams = useCallback(() => {
    let apiUrl =
      `${endpoints}?` +
      `&sortByColumn=${sortByColumn}&orderBy=${orderBy}` +
      `&pageNo=${selectedPage}&pageSize=${predefinedValues.pageSize}&search=${encodeURIComponent(searchKeyWord)}&isActive=${isActive}`;

    return apiUrl;
  }, [sortByColumn, orderBy, selectedPage, endpoints, searchKeyWord, isActive]);

  const fetchIngestionAccounts = useCallback(() => {
    //api call for table data
    const newController = new AbortController();
    setController(newController);

    const apiUrl = getUrlParams();
    if (apiUrl) {
      setIsLoading(true);
      axiosHttp
        .get(apiUrl, { signal: newController.signal })
        .then((response) => {
          dispatch(setTableResponse(response.data));
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            setIsLoading(false);
            console.error("Error:", error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUrlParams]);

  const fetchAccountCardContent = useCallback(() => {
    const apiUrl = `${PATHS.INGESTION.ACCOUNT_SUMMARY}`;
    axiosHttp
      .get(apiUrl)
      .then((response) => {
        const data = response.data.response;
        const accountsCardNumbers = [
          data.total,
          data.total_active,
          data.total_inactive
        ];
        dispatch(
          setIngestionAccountsCardContent(
            ingestionAccountsCardImages.map((image, index) => {
              return {
                headerText: accountsCardNumbers[index]?.toString() ?? "---",
                bodyText: staticStrings.ingestion.accounts.cards[index],
                image: image ?? "",
                id: image + index
              };
            })
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [dispatch]);

  const searchKeyWordHandler = (keyWord: string) => {
    dispatch(setSearchKeyWord(keyWord));
    dispatch(setSelectedPage(1));
  };

  useEffect(() => {
    // Cleanup function to abort the previous request when the component unmounts or controller changes
    return () => controller.abort();
  }, [controller]);

  useEffect(() => {
    fetchAccountCardContent();
  }, [fetchAccountCardContent]);

  useEffect(() => {
    fetchIngestionAccounts();
  }, [fetchIngestionAccounts]);

  return (
    <div>
      <IngestionAccountHeader setSearchKeyWord={searchKeyWordHandler} />
      <IngestionAccountsTable
        tableHeader={tableHeader}
        isLoading={isLoading}
        fetchIngestionAccounts={fetchIngestionAccounts}
        fetchAccountCardContent={fetchAccountCardContent}
      />
    </div>
  );
};

export default IngestionAccounts;
