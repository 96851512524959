import { IUser } from "@interfaces";

export interface IEmail {
  id: string;
  subject: string;
  sender: IUser;
  participants: IUser[];
  internal_date: string;
}

interface IEmailData {
  total_time_spent_in_minutes: number;
  emails: IEmail[];
}

interface IEmailResponse {
  page_no: number;
  total: number;
  data: IEmailData;
}

export interface IEmaiAPIlData {
  error?: string;
  response?: IEmailResponse;
}

export enum ParticipantType {
  SENDER = "sender",
  TO = "to",
  CC = "cc"
}
