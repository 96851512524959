import Button from "@clayui/button";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayIcon from "@clayui/icon";
import spritemap from "@images/icons.svg";
import { useState } from "react";
import styles from "./DropdownSelectBox.module.scss";
import { staticStrings } from "@staticStrings";
import { ContainerLoader } from "../container-loader/ContainerLoader";

interface DropdownSelectBoxProps {
  selectedOption: string;
  options: string[];
  setSelectedOption: (option: string) => void;
  dropdownId: string;
  placeHolder?: string;
  disableFlag?: boolean;
  loader?: boolean;
}

const DropdownSelectBox: React.FC<DropdownSelectBoxProps> = ({
  selectedOption,
  options,
  setSelectedOption,
  dropdownId,
  placeHolder = staticStrings.commonTexts.select,
  disableFlag = false,
  loader = false
}) => {
  const [open, setOpen] = useState(false);

  return (
    <DropDown
      active={open}
      className={styles.dropDown}
      filterKey=""
      onActiveChange={setOpen}
      trigger={
        <Button
          className={styles.dropDownBtn}
          displayType="unstyled"
          onClick={() => setOpen(!open)}
          id={dropdownId}
          disabled={disableFlag}
        >
          <div className={styles.dropDownText}>
            {selectedOption !== "" ? selectedOption : placeHolder}
          </div>
          {!open ? (
            <ClayIcon
              symbol="angle-down"
              spritemap={spritemap}
              className={styles.arrowDown}
            />
          ) : (
            <ClayIcon
              symbol="angle-up"
              spritemap={spritemap}
              className={styles.arrowUp}
            />
          )}
        </Button>
      }
    >
      <DropDown.ItemList items={options} className={styles.dropDownItem}>
        {!loader ? (
          options?.map((item, index) => {
            return (
              <DropDown.Item
                id={"dropdown_list_" + dropdownId + index}
                key={item}
                active={item === selectedOption}
                onClick={(e: any) => {
                  setSelectedOption(e.target.textContent);
                  setOpen(!open);
                }}
              >
                {item}
              </DropDown.Item>
            );
          })
        ) : (
          <div className={styles.containerLoader}>
            <ContainerLoader msgRequired={false} />
          </div>
        )}
      </DropDown.ItemList>
    </DropDown>
  );
};

export { DropdownSelectBox };
