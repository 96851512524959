import { createSlice } from "@reduxjs/toolkit";
import {
  IUserManagementTableData,
  IUserManagementUsersCardDetails,
  IUserManagementUsersTableResponse
} from "interfaces/userManagement.interface";
import {
  DEFAULT_ORDER_BY_USER_MANAGEMENT,
  DEFAULT_SORT_BY_USER_MANAGEMENT
} from "../../utils/constants/reducerConstants";
import { IList as IListNameOptional } from "../../common-ui-modules/common-components/DropdownCheckBox/DropdownCheckBox";

interface UserManagementInterface {
  tableResponse: IUserManagementUsersTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  suspendHeader: string;
  selectedUserId: string;
  selectedPopUpPage: number;
  usersCardContent: IUserManagementUsersCardDetails[];
  stepperDropdownTable: IUserManagementTableData[];
  emailFetchSuccessFlag: boolean;
  permissionSelectedFlag: boolean;
  visibilityLevelSelectedFlag: boolean;
  adminCheckFlag: boolean;
  stepperTablePresentFlag: boolean;
  toastMessage: string;
  userEmailDoesNotExistFlag: boolean;
  activeTab: number;
  visibilityLevelLabel: string;
  searchKeyWord: string;
  salesModalDescription: string;
  salesModalAction: string;
  submitButtonText: string;
  userPermissionData: string;
  locationLists: string[];
  isLocationLoading: boolean;
  locationItem: IListNameOptional[] | null;
  ingestionStatus: string;
}

const initialState: UserManagementInterface = {
  orderBy: DEFAULT_ORDER_BY_USER_MANAGEMENT,
  sortByColumn: DEFAULT_SORT_BY_USER_MANAGEMENT,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  suspendHeader: "",
  selectedUserId: "",
  selectedPopUpPage: 1,
  usersCardContent: [],
  emailFetchSuccessFlag: false,
  permissionSelectedFlag: false,
  stepperDropdownTable: [],
  adminCheckFlag: false,
  toastMessage: "",
  visibilityLevelSelectedFlag: false,
  stepperTablePresentFlag: false,
  userEmailDoesNotExistFlag: false,
  activeTab: 0,
  visibilityLevelLabel: "Select",
  searchKeyWord: "",
  salesModalDescription: "",
  salesModalAction: "",
  submitButtonText: "",
  userPermissionData: "",
  locationLists: [],
  isLocationLoading: false,
  locationItem: [],
  ingestionStatus: ""
};

const initApiState = {
  orderBy: DEFAULT_ORDER_BY_USER_MANAGEMENT,
  sortByColumn: DEFAULT_SORT_BY_USER_MANAGEMENT,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  searchKeyWord: "",
  ingestionStatus: ""
};

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSuspendHeader: (state, action) => {
      state.suspendHeader = action.payload;
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    setSelectedPopUpPage: (state, action) => {
      state.selectedPopUpPage = action.payload;
    },
    setUsersCardContent: (state, action) => {
      state.usersCardContent = action.payload;
    },
    setEmailFetchSuccessFlag: (state, action) => {
      state.emailFetchSuccessFlag = action.payload;
    },
    setPermissionSelectedFlag: (state, action) => {
      state.permissionSelectedFlag = action.payload;
    },
    setStepperDropdownTable: (state, action) => {
      state.stepperDropdownTable = action.payload;
    },
    setAdminCheckFlag: (state, action) => {
      state.adminCheckFlag = action.payload;
    },
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },
    setVisibilityLevelSelectedFlag: (state, action) => {
      state.visibilityLevelSelectedFlag = action.payload;
    },
    setStepperTablePresentFlag: (state, action) => {
      state.stepperTablePresentFlag = action.payload;
    },
    setUserEmailDoesNotExistFlag: (state, action) => {
      state.userEmailDoesNotExistFlag = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setVisibilityLevelLabel: (state, action) => {
      state.visibilityLevelLabel = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setSalesModalDescription: (state, action) => {
      state.salesModalDescription = action.payload;
    },
    setSalesModalAction: (state, action) => {
      state.salesModalAction = action.payload;
    },
    setSubmitButtonText: (state, action) => {
      state.submitButtonText = action.payload;
    },
    setUserPermissionData: (state, action) => {
      state.userPermissionData = action.payload;
    },
    setLocationsLists: (state, action) => {
      state.locationLists = action.payload;
    },
    setIsLocationLoading: (state, action) => {
      state.isLocationLoading = action.payload;
    },
    setLocationItem: (state, action) => {
      state.locationItem = action.payload;
    },
    setIngestionStatus: (state, action) => {
      state.ingestionStatus = action.payload;
    },
    setInitUserManagement: (state) => {
      const stateObject = {
        ...state,
        ...initApiState
      };
      return stateObject;
    }
  }
});

export const {
  setTableResponse,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setSuspendHeader,
  setSelectedUserId,
  setSelectedPopUpPage,
  setUsersCardContent,
  setEmailFetchSuccessFlag,
  setPermissionSelectedFlag,
  setStepperDropdownTable,
  setAdminCheckFlag,
  setToastMessage,
  setVisibilityLevelSelectedFlag,
  setStepperTablePresentFlag,
  setUserEmailDoesNotExistFlag,
  setActiveTab,
  setVisibilityLevelLabel,
  setSearchKeyWord,
  setSalesModalDescription,
  setSalesModalAction,
  setSubmitButtonText,
  setUserPermissionData,
  setInitUserManagement,
  setLocationsLists,
  setIsLocationLoading,
  setLocationItem,
  setIngestionStatus
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
