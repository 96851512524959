import { ClayIconSpriteContext } from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import "@clayui/css/lib/css/atlas.css";
import spritemap from "@images/icons.svg";
import styles from "./FilterIngestion.module.scss";
import FilterIngestionHeader from "@components/FilterIngestionHeader/FilterIngestionHeader";
import FilterIngestionTable from "@components/FilterIngestionTable/FilterIngestionTable";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setTableResponse,
  setFilterOptionsResponse,
  setSelectedFilterId,
  setIsSavingFilter,
  setSelectedPage,
  setSearchKeyWord,
  setInitFilterIngestion
} from "../../../../store/filterIngestion/filterIngestion.slice";
import { RootState } from "store/configureStore";
import {
  predefinedValues,
  tableHeader
} from "@pages/filterIngestion/Tabs/FilterIngestion/FilterIngestionConstants";
import { PATHS } from "@constants";
import { SalesAlert } from "@common-components";
import { staticStrings } from "@staticStrings";
import {
  IAddFilterRequestBody,
  IFilterIngestionTableData,
  IFormData
} from "interfaces/filterIngestion.interface";
import FilterIngestionPopUp from "@components/FilterIngestionPopUp/FilterIngestionPopUp";
import { useModal } from "@clayui/modal";
import { axiosHttp } from "@common-services";

const FilterIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [rowData, setRowData] = useState<IFilterIngestionTableData>();
  const controller = new AbortController();

  const {
    observer: observerFilterIngestion,
    onOpenChange: onOpenChangeFilterIngestion,
    open: openFilterIngestion
  } = useModal();

  const dispatch = useDispatch();

  const {
    orderBy,
    sortByColumn,
    selectedPage,
    filterTypeParam,
    filterFieldParam,
    selectedFilterId,
    searchKeyWord
  } = useSelector((state: RootState) => state.filterIngestion);

  const addParam = (param: string, newParam: string): string => {
    if (param !== ``) {
      return param + `&` + newParam;
    }
    return newParam;
  };

  const getUrlParams = useCallback(() => {
    let urlParams = `pageSize=${predefinedValues.pageSize}&pageNo=${selectedPage}`;

    if (searchKeyWord !== "") {
      urlParams = addParam(
        urlParams,
        `search=${encodeURIComponent(searchKeyWord)}`
      );
    }

    if (sortByColumn !== "" && orderBy !== "")
      urlParams = addParam(
        urlParams,
        `sortByColumn=${sortByColumn}&orderBy=${orderBy}`
      );

    if (
      filterTypeParam !== "" &&
      filterTypeParam !== staticStrings.filterIngestion.any
    )
      urlParams = addParam(urlParams, `type=${filterTypeParam.toUpperCase()}`);

    if (
      filterFieldParam !== "" &&
      filterFieldParam !== staticStrings.filterIngestion.any
    )
      urlParams = addParam(
        urlParams,
        `field=${filterFieldParam.toUpperCase()}`
      );

    return urlParams;
  }, [
    sortByColumn,
    orderBy,
    selectedPage,
    searchKeyWord,
    filterTypeParam,
    filterFieldParam
  ]);

  const fetchTableData = useCallback(async () => {
    //api call for table data
    const signal = controller.signal;
    const apiUrl = `${PATHS.FILTER_INGESTION.LIST()}?${getUrlParams()}`;

    setIsLoading(true);
    axiosHttp
      .get(apiUrl, { signal })
      .then((response) => {
        dispatch(setTableResponse(response.data));
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.code !== "ERR_CANCELED") {
          setIsLoading(false);
          console.error("Error:", error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUrlParams]);

  const handleAddEditSubmit = async (values: IFormData) => {
    dispatch(setIsSavingFilter(true));
    const requestBody: IAddFilterRequestBody = {
      filter_type: values.type.toUpperCase(),
      filter_field: values.field.toUpperCase(),
      pattern: values.pattern
    };
    if (values.filter_id) requestBody.id = values.filter_id;

    try {
      await axiosHttp.post(PATHS.FILTER_INGESTION.LIST(), requestBody);
      fetchTableData();
      onOpenChangeFilterIngestion(false);
      dispatch(setIsSavingFilter(false));
      setSuccessMessage(
        values.filter_id
          ? staticStrings.filterIngestion.editFilterSuccess
          : staticStrings.filterIngestion.addFilterSuccess
      );
      setAlertOpen(true);
      const timer = setTimeout(() => setAlertOpen(false), 5000);
      return () => clearTimeout(timer);
    } catch (error) {
      dispatch(setIsSavingFilter(false));
      console.error("Error:", error);
    }
  };

  const handleDeleteFilter = async () => {
    setAlertOpen(false);

    try {
      await axiosHttp.delete(PATHS.FILTER_INGESTION.LIST(), {
        data: { id: selectedFilterId }
      });
      fetchTableData();
      dispatch(setSelectedFilterId(""));
      setSuccessMessage(staticStrings.filterIngestion.deleteSuccess);
      setAlertOpen(true);
      const timer = setTimeout(() => setAlertOpen(false), 5000);
      return () => clearTimeout(timer);
    } catch (error) {
      dispatch(setSelectedFilterId(""));
      console.error("Error:", error);
    }
  };

  const searchKeyWordHandler = (keyWord: string) => {
    dispatch(setSearchKeyWord(keyWord));
    dispatch(setSelectedPage(1));
  };

  useEffect(() => {
    fetchTableData();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTableData]);

  useEffect(() => {
    const fetchData = async () => {
      //api call for fetching the filter inputs
      const apiUrl = `${PATHS.FILTER_INGESTION.FETCH_TYPES_FIELDS()}`;
      try {
        const response = await axiosHttp.get(apiUrl);
        dispatch(setFilterOptionsResponse(response.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setInitFilterIngestion());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ClayIconSpriteContext.Provider value={spritemap}>
      <div className={styles.filterIngestionContainer}>
        <ClayLayout.Row className={styles.ingestionHeaderContainer}>
          <FilterIngestionHeader
            setSearchKeyWord={searchKeyWordHandler}
            onOpenChange={onOpenChangeFilterIngestion}
            setRowData={setRowData}
          />
        </ClayLayout.Row>
        <ClayLayout.Row className={styles.ingestionTableContainer}>
          <FilterIngestionTable
            tableHeader={tableHeader}
            handleDeleteFilter={handleDeleteFilter}
            setRowData={setRowData}
            onOpenChangeFilterIngestion={onOpenChangeFilterIngestion}
            isLoading={isLoading}
          />
        </ClayLayout.Row>
      </div>

      {alertOpen && (
        <SalesAlert setAlertOpen={setAlertOpen} message={successMessage} />
      )}

      <FilterIngestionPopUp
        open={openFilterIngestion}
        observer={observerFilterIngestion}
        onOpenChange={onOpenChangeFilterIngestion}
        popUpTitle={
          rowData?.id
            ? staticStrings.filterIngestion.editFilter
            : staticStrings.filterIngestion.addFilter
        }
        rowData={rowData}
        onSubmit={handleAddEditSubmit}
      />
    </ClayIconSpriteContext.Provider>
  );
};
export default FilterIngestion;
