import ClayTabs from "@clayui/tabs";
import AccountsImage from "@images/userManagement/UserManagementTotal.svg";
import DomainsHeader from "@components/DomainsHeader/DomainsHeader";
import DomainsTable from "@components/DomainsTable/DomainsTable";
import { predefinedValues, tableHeader } from "./DomainsTableConstant";
import { useCallback, useEffect, useState } from "react";
import { axiosHttp } from "@common-services";
import { PATHS } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { staticStrings } from "@staticStrings";
import {
  setDomainsCardContent,
  setInitDomainIngestion,
  setSearchKeyWord,
  setSelectedPage,
  setTableResponse
} from "@store/filterIngestion/domains/domains.slice";
import { RootState } from "@store/configureStore";
import { IAccountsFilter } from "interfaces/domains.interface";
import DomainsImage from "@images/DomainsIcon.svg";

const Domains = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const [accountsFilter, setAccountsFilter] = useState<IAccountsFilter>({
    action_type: staticStrings.account360.engagedPeople.include,
    account_ids: []
  });
  const endpoints = PATHS.INGESTION.DOMAINS.LIST;
  const dispatch = useDispatch();
  const { sortByColumn, orderBy, selectedPage, searchKeyWord } = useSelector(
    (state: RootState) => state.domains
  );

  const getUrlParams = useCallback(() => {
    let apiUrl =
      `${endpoints}?` +
      `&sortByColumn=${sortByColumn}&orderBy=${orderBy}` +
      `&pageNo=${selectedPage}&pageSize=${predefinedValues.pageSize}&search=${encodeURIComponent(searchKeyWord)}`;

    return apiUrl;
  }, [sortByColumn, orderBy, selectedPage, endpoints, searchKeyWord]);
  const fetchDomainsTable = useCallback(() => {
    //api call for table data
    const newController = new AbortController();
    setController(newController);

    const apiUrl = getUrlParams();
    if (apiUrl) {
      setIsLoading(true);

      axiosHttp
        .post(apiUrl, accountsFilter, { signal: newController.signal })
        .then((response) => {
          dispatch(setTableResponse(response.data));
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            setIsLoading(false);
            console.error("Error:", error);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getUrlParams, accountsFilter]);

  const fetchDomainsCardContent = useCallback(() => {
    const apiUrl = `${PATHS.INGESTION.DOMAINS.DOMAINS_ACCOUNTS}`;
    axiosHttp
      .post(apiUrl)
      .then((response) => {
        const data = response?.data?.response;
        dispatch(
          setDomainsCardContent([
            {
              headerText: data?.total?.toString() ?? "---",
              bodyText: staticStrings.ingestion.domains.accountsCard,
              image: AccountsImage ?? "",
              id: AccountsImage + 0
            },
            {
              headerText: data?.total_domains?.toString(),
              bodyText: staticStrings.ingestion.domains.domainsCard,
              image: DomainsImage ?? "",
              id: DomainsImage + 1
            }
          ])
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [dispatch]);
  useEffect(() => {
    fetchDomainsCardContent();
  }, [fetchDomainsCardContent]);
  useEffect(() => {
    fetchDomainsTable();
  }, [fetchDomainsTable]);

  const searchKeyWordHandler = (keyWord: string) => {
    dispatch(setSearchKeyWord(keyWord));
    dispatch(setSelectedPage(1));
  };
  useEffect(() => {
    // Cleanup function to abort the previous request when the component unmounts or controller changes
    return () => controller.abort();
  }, [controller]);

  useEffect(() => {
    return () => {
      dispatch(setInitDomainIngestion());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ClayTabs.Content>
        <ClayTabs.TabPane aria-labelledby="tab-1">
          <div>
            <DomainsHeader
              setSearchKeyWord={searchKeyWordHandler}
              setAccountsFilter={setAccountsFilter}
            />

            <DomainsTable tableHeader={tableHeader} isLoading={isLoading} />
          </div>
        </ClayTabs.TabPane>
      </ClayTabs.Content>
    </div>
  );
};

export default Domains;
