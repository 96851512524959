import { createSlice } from "@reduxjs/toolkit";
import { staticStrings } from "@staticStrings";
import {
  IFilterIngestionTableResponse,
  IFilterOptionsResponse
} from "interfaces/filterIngestion.interface";

interface FilterIngestionInterface {
  tableResponse: IFilterIngestionTableResponse;
  filterOptionsResponse: IFilterOptionsResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  selectedFilterId: string;
  filterTypeParam: string;
  filterFieldParam: string;
  isSavingFilter: boolean;
  searchKeyWord: string;
}

const initialState: FilterIngestionInterface = {
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: []
    }
  },
  filterOptionsResponse: {
    error: null,
    response: {
      EMAILS: [],
      MEETINGS: [],
      ANY: [staticStrings.filterIngestion.any]
    }
  },
  orderBy: "",
  sortByColumn: "",
  selectedPage: 1,
  selectedFilterId: "",
  filterTypeParam: staticStrings.filterIngestion.any,
  filterFieldParam: staticStrings.filterIngestion.any,
  isSavingFilter: false,
  searchKeyWord: ""
};

const filterIngestionSlice = createSlice({
  name: "filterIngestion",
  initialState,
  reducers: {
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setFilterOptionsResponse: (state, action) => {
      state.filterOptionsResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedFilterId: (state, action) => {
      state.selectedFilterId = action.payload;
    },
    setFilterTypeParam: (state, action) => {
      state.filterTypeParam = action.payload;
    },
    setFilterFieldParam: (state, action) => {
      state.filterFieldParam = action.payload;
    },
    setIsSavingFilter: (state, action) => {
      state.isSavingFilter = action.payload;
    },
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setInitFilterIngestion: () => {
      return initialState;
    }
  }
});

export const {
  setTableResponse,
  setFilterOptionsResponse,
  setOrderBy,
  setSortByColumn,
  setSelectedPage,
  setSelectedFilterId,
  setFilterTypeParam,
  setFilterFieldParam,
  setIsSavingFilter,
  setSearchKeyWord,
  setInitFilterIngestion
} = filterIngestionSlice.actions;
export default filterIngestionSlice.reducer;
