// PermissionChecker.tsx
import React, { ReactNode } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "store/configureStore";

interface PermissionCheckerProps {
  userPermissions: string[];
  requiredPermission?: string;
  children: ReactNode;
  omitChecking?: boolean;
}

const PermissionChecker: React.FC<
  ConnectedProps<typeof connector> & PermissionCheckerProps
> = ({
  userPermissions,
  requiredPermission,
  omitChecking = false,
  children
}) => {
  // Check if the user has the required permission
  let hasPermission = true;
  if (!omitChecking) {
    hasPermission = userPermissions.includes(requiredPermission);
  }

  return hasPermission ? <div>{children}</div> : <></>;
};

// Map Redux state to component props
const mapStateToProps = (state: RootState) => ({
  userPermissions: state.config.userPermissions
});

const connector = connect(mapStateToProps);

export default connector(PermissionChecker);
