import UserIcon from "@images/userIcon.png";
import styles from "./UserImage.module.scss";
import React from "react";
import UserInactiveIcon from "@images/UserInactiveIcon.svg";
interface IUserImageProps {
  imageLink?: string;
  tooltipKey?: string;
  is_active: boolean;
}

const UserImage: React.FC<IUserImageProps> = ({
  imageLink,
  tooltipKey,
  is_active
}) => {
  const profileImageLink = is_active ? imageLink : UserInactiveIcon;
  return (
    <div className={styles.iconContainer}>
      <img
        src={profileImageLink ?? UserIcon}
        alt="user-icon"
        className={styles.icon}
        data-tooltip-id={tooltipKey}
      />
    </div>
  );
};
export { UserImage };
