import { IAccountTableResponse } from "@interfaces";
import { createSlice } from "@reduxjs/toolkit";
import {
  DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING_DETAILS,
  DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING_DETAILS
} from "../../../utils/constants/reducerConstants";

interface CustomTeamReportingDetailsInterface {
  orderByCustomTeamReportingDetails: string;
  sortByColumnCustomTeamReportingDetails: string;
  userFilterListCustomTeamReportingDetails: string[];
  tableResponseCustomTeamReportingDetails: IAccountTableResponse | {};
  selectedPageCustomTeamReportingDetails: number;
}

const initialState: CustomTeamReportingDetailsInterface = {
  orderByCustomTeamReportingDetails:
    DEFAULT_ORDER_BY_CUSTOM_TEAM_REPORTING_DETAILS,
  sortByColumnCustomTeamReportingDetails:
    DEFAULT_SORT_BY_CUSTOM_TEAM_REPORTING_DETAILS,
  userFilterListCustomTeamReportingDetails: [],
  tableResponseCustomTeamReportingDetails: {
    response: {
      error: null,
      page_no: 0,
      total: 0,
      data: []
    }
  },
  selectedPageCustomTeamReportingDetails: 1
};

const customTeamReportingDetails = createSlice({
  name: "customTeamReportingDetailsDetails",
  initialState,
  reducers: {
    setOrderByCustomTeamReportingDetails: (state, action) => {
      state.orderByCustomTeamReportingDetails = action.payload;
    },
    setSortByColumnCustomTeamReportingDetails: (state, action) => {
      state.sortByColumnCustomTeamReportingDetails = action.payload;
    },
    setUserFilterListCustomTeamReportingDetails: (state, action) => {
      state.userFilterListCustomTeamReportingDetails = action.payload;
    },
    setTableResponseCustomTeamReportingDetails: (state, action) => {
      state.tableResponseCustomTeamReportingDetails = action.payload;
    },
    setSelectedPageCustomTeamReportingDetails: (state, action) => {
      state.selectedPageCustomTeamReportingDetails = action.payload;
    },
    setInitialStateCustomTeamReportingDetails: () => {
      return initialState;
    }
  }
});

export const {
  setOrderByCustomTeamReportingDetails,
  setSortByColumnCustomTeamReportingDetails,
  setUserFilterListCustomTeamReportingDetails,
  setTableResponseCustomTeamReportingDetails,
  setSelectedPageCustomTeamReportingDetails,
  setInitialStateCustomTeamReportingDetails
} = customTeamReportingDetails.actions;
export default customTeamReportingDetails.reducer;
