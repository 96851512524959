import React from "react";
import ClayForm, { ClayInput } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";
import styles from "./Controls.module.scss";
interface ITextProps {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
}
const Text: React.FC<ITextProps> = ({ name, label, placeholder, disabled }) => {
  return (
    <React.Fragment>
      {label && (
        <label htmlFor={name} className={styles.labelText}>
          {label}
        </label>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { value, onTypeShow } = field;
          return (
            <ClayForm.Group
              className={`form-group-sm ${
                form.errors[name] && form.touched[name] !== undefined
                  ? "has-error"
                  : ""
              } ${styles.textContainer}`}
            >
              <ClayInput
                type="text"
                value={value}
                placeholder={placeholder || label}
                {...field}
                className={`${styles.formButton} ${styles.searchInput}`}
                disabled={disabled}
                onKeyUp={() => form.setFieldTouched(name)}
              />
              {!onTypeShow && (
                <ErrorMessage name={name} component={TextError} />
              )}
            </ClayForm.Group>
          );
        }}
      </Field>
    </React.Fragment>
  );
};

export default Text;
