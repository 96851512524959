import { Cell } from "@clayui/core";
import styles from "../../../../styles/property_definitions/tableStyle.module.scss";

type Props = {
  cellValue?: string | number | null;
  cellClassName?: any;
  location: string;
};

const SingleValueCellMasked = ({
  cellValue,
  cellClassName = "tableContainerTableBodyRowCellTextLightBold",
  location
}: Props) => {
  if (
    location &&
    JSON.parse(window?.env?.RESTRICTED_LOCATION ?? "[]").includes(
      location.toString().substring(0, 3)
    )
  ) {
    return <Cell className={styles[cellClassName]}>{"---"}</Cell>;
  }
  return (
    <Cell className={styles[cellClassName]}>
      {cellValue?.toString() ? cellValue : "---"}
    </Cell>
  );
};

export { SingleValueCellMasked };
