import { Cell } from "@clayui/core";
import React from "react";
import styles from "../../../../styles/property_definitions/tableStyle.module.scss";

type Props = {
  cellValue?: string | number | null;
  cellClassName?: any;
};

const SingleValueCell = ({
  cellValue,
  cellClassName = "tableContainerTableBodyRowCellTextLightBold"
}: Props) => {
  return (
    <Cell className={styles[cellClassName]}>
      {cellValue?.toString() ? cellValue : "---"}
    </Cell>
  );
};

export { SingleValueCell };
