import {
  DEFAULT_ORDER_BY_ACCOUNT_INGESTION,
  DEFAULT_SORT_BY_ACCOUNT_INGESTION
} from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import {
  IUserAccountsCardDetails,
  IUserAccountsTableResponse
} from "interfaces/userAccounts.interface";

interface UserAccountsInterface {
  userAccountsCardContent: IUserAccountsCardDetails[];
  searchKeyWord: string;
  tableResponse: IUserAccountsTableResponse;
  orderBy: string;
  sortByColumn: string;
  selectedPage: number;
  selectedUserEmail: string;
  userAccountsTitle: string;
  checkedAccountIds: string[];
}

const initialState: UserAccountsInterface = {
  userAccountsCardContent: [],
  searchKeyWord: "",
  orderBy: DEFAULT_ORDER_BY_ACCOUNT_INGESTION,
  sortByColumn: DEFAULT_SORT_BY_ACCOUNT_INGESTION,
  selectedPage: 1,
  tableResponse: {
    error: null,
    response: {
      page_no: 0,
      total: 0,
      data: { accounts: [], user_name: "" }
    }
  },
  selectedUserEmail: "",
  userAccountsTitle: "",
  checkedAccountIds: []
};

const userAccountsSlice = createSlice({
  name: "userAccounts",
  initialState,
  reducers: {
    setSearchKeyWord: (state, action) => {
      state.searchKeyWord = action.payload;
    },
    setUserAccountsCardContent: (state, action) => {
      state.userAccountsCardContent = action.payload;
    },
    setTableResponse: (state, action) => {
      state.tableResponse = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setSortByColumn: (state, action) => {
      state.sortByColumn = action.payload;
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload;
    },
    setSelectedUserEmail: (state, action) => {
      state.selectedUserEmail = action.payload;
    },
    setSelectedUserAccountsTitle: (state, action) => {
      state.userAccountsTitle = action.payload;
    },
    setCheckedAccountIds: (state, action) => {
      state.checkedAccountIds = action.payload;
    },
    setInitUserAccounts: () => {
      return initialState;
    }
  }
});

export const {
  setSearchKeyWord,
  setOrderBy,
  setSelectedPage,
  setSortByColumn,
  setTableResponse,
  setSelectedUserAccountsTitle,
  setSelectedUserEmail,
  setUserAccountsCardContent,
  setInitUserAccounts,
  setCheckedAccountIds
} = userAccountsSlice.actions;
export default userAccountsSlice.reducer;
