import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayIcon from "@clayui/icon";
import styles from "./DropdownCheckBox.module.scss";
import { staticStrings } from "@staticStrings";
import spritemap from "@images/icons.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import ClayButton from "@clayui/button";
import { ClayCheckbox } from "@clayui/form";
import SearchInput from "@components/SearchInput/SearchInput";
import { HelperFunctions, debounce } from "@utils";
import { ContainerLoader } from "../container-loader/ContainerLoader";
import LazyLoadingContainer from "@components/DetailsPage/LazyLoadingContainer/LazyLoadingContainer";
import CustomButton from "../CustomButton/CustomButton";
import { staticIds } from "@staticIds";

export interface IList {
  name: string;
  id: string;
  value: string;
}

/**
 * @param {IList[]} list  - list which will be display in checkbox
 * @param {string[]} setState  - if you need simple useState for setting the value
 * @param {string[]} stateValue  - for setting the value user selected on clicking of done (if used setState)
 * @param {boolean} isSelectAll  - if you want all checkbox or not
 * @param {boolean} isCancel  - if you want cancel button or not
 * @param {boolean} displaySubheading  - if you want two fields to be display in checkbox (eg: email and name)
 **/

interface DropdownCheckBoxProps {
  list: IList[];
  searchResponse: IList[];
  selectedValues: string[];
  setSelectedValues: (idArray: string[]) => void;
  unselectedValues: string[];
  setUnselectedValues: (idArray: string[]) => void;
  isSelectAll?: boolean;
  dropdownLabel: string;
  btnClass?: string;
  displaySubheading?: boolean;
  isCancel?: boolean;
  isLoading: boolean;
  loadMoreAccounts: () => void;
  searchKeyWord: string;
  setSearchKeyWord: (searchInput: string) => void;
  setSubmitFlag: (flag: boolean) => void;
  isLoadingInitialData: boolean;
  totalItems: number;
  selectedCount: number;
  setSelectedCount: (count: number) => void;
  setIsLoadingInitialData: (isLoading: boolean) => void;
  setClearClickFlag: (flag: boolean) => void;
  tempUnselectedValues: string[];
  setTempUnselectedValues: (idArray: string[]) => void;
  clearClickFlag: boolean;
  setSubmitDisableFlag: (flag: boolean) => void;
  dropDownUpdateFlag: boolean;
  setDropDownUpdateFlag: (flag: boolean) => void;
  setLoadingClearClick: (flag: boolean) => void;
  setUnfilteredList: (itemList: IList[]) => void;
}

const DropdownCheckBoxWithLazyLoading = ({
  list,
  searchResponse,
  selectedValues,
  setSelectedValues,
  selectedCount,
  setSelectedCount,
  unselectedValues,
  setUnselectedValues,
  isSelectAll = true,
  dropdownLabel,
  btnClass,
  displaySubheading = false,
  isLoading,
  loadMoreAccounts,
  searchKeyWord,
  setSearchKeyWord,
  setSubmitFlag,
  isLoadingInitialData,
  totalItems,
  setClearClickFlag,
  tempUnselectedValues,
  setTempUnselectedValues,
  clearClickFlag,
  dropDownUpdateFlag,
  setDropDownUpdateFlag,
  setSubmitDisableFlag,
  setLoadingClearClick,
  setUnfilteredList
}: DropdownCheckBoxProps) => {
  const [dropdownList, setDropDownList] = useState<IList[]>(
    searchKeyWord.length > 0 ? searchResponse : list
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [tempSelectedValues, setTempSelectedValues] = useState<string[]>([]);
  const divRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    setDropDownList(searchKeyWord.length > 0 ? searchResponse : list);
  }, [searchResponse, list, searchKeyWord]);

  const moveCheckedItemOnTop = () => {
    const selectedItemBySearch = searchResponse.filter((item) =>
      selectedValues.includes(item.id)
    );

    const uniqueItems = HelperFunctions.getUniqueDropdownItemList(
      list,
      selectedItemBySearch
    );
    setUnfilteredList(
      HelperFunctions.sortDropDownList(uniqueItems, tempSelectedValues)
    );
  };

  const checkSelectAll = (updatedSelectedRows: string[]) => {
    const selectedFromList =
      dropdownList &&
      dropdownList.length > 0 &&
      dropdownList.every((item: IList) =>
        updatedSelectedRows.includes(item.value)
      );

    if (searchKeyWord.length > 0) {
      return selectedFromList;
    } else {
      return tempUnselectedValues.length === 0 && selectedFromList;
    }
  };

  useEffect(() => {
    setSelectAll(checkSelectAll(tempSelectedValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempSelectedValues, tempUnselectedValues, searchKeyWord, dropdownList]);

  const clearChecked = () => {
    if (searchKeyWord.length > 0) {
      const selectedData = searchResponse.map((obj) => obj.value);
      const resultData = tempSelectedValues.filter(
        (item) => !selectedData.includes(item)
      );
      setTempSelectedValues(resultData);

      const unCheckedItems = Array.from(
        new Set([...tempUnselectedValues, ...selectedData])
      );
      setTempUnselectedValues(unCheckedItems);

      setClearClickFlag(false);
    } else {
      setTempSelectedValues([]);

      setTempUnselectedValues([...list.map((item) => item.value)]);
      setClearClickFlag(true);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      // Deselect all items on the current page
      clearChecked();
    } else {
      // Select all items on the current page
      if (searchKeyWord.length > 0) {
        const addedItems = searchResponse.map((obj) => obj.value);
        const checkedItems = Array.from(
          new Set([...tempSelectedValues, ...addedItems])
        );
        const unCheckedItems = tempUnselectedValues.filter(
          (item) => !addedItems.includes(item)
        );

        setTempSelectedValues(checkedItems);

        setTempUnselectedValues(unCheckedItems);
      } else {
        setTempSelectedValues([...list.map((item) => item.value)]);

        setTempUnselectedValues([]);
        setClearClickFlag(false);
        setLoadingClearClick(false);
      }
    }
    setSelectAll(!selectAll);
  };

  const handleClear = () => {
    clearChecked();
    setSearchInput("");
    setSearchKeyWord("");
    setSelectAll(false);
  };

  const handleSubmit = () => {
    setSelectedValues(tempSelectedValues);
    setUnselectedValues(tempUnselectedValues);
    if (
      tempSelectedValues.length === list.length &&
      tempUnselectedValues.length === 0
    ) {
      setSelectedCount(totalItems);
    } else if (totalItems !== list.length) {
      if (clearClickFlag) {
        setSelectedCount(tempSelectedValues.length);
      } else {
        setSelectedCount(totalItems - tempUnselectedValues.length);
      }
    } else {
      setSelectedCount(tempSelectedValues.length);
    }
    if (clearClickFlag) {
      setLoadingClearClick(true);
    }
    setOpen(!open);
    setSubmitFlag(true);
    setSearchInput("");
    setSearchKeyWord("");
    moveCheckedItemOnTop();
  };

  const toggleCheckbox = (value: string) => {
    let checkedItems: string[];
    let unCheckedItems: string[];
    if (tempSelectedValues.includes(value)) {
      setSelectAll(false);
      checkedItems = tempSelectedValues.filter(
        (currentValue) => currentValue !== value
      );
      unCheckedItems = [...tempUnselectedValues, value];
    } else {
      checkedItems = [...tempSelectedValues, value];
      setSelectAll(checkSelectAll(checkedItems) && !searchKeyWord);
      unCheckedItems = tempUnselectedValues.filter(
        (currentValue) => currentValue !== value
      );
    }

    setTempSelectedValues(checkedItems);

    setTempUnselectedValues(unCheckedItems);

    if (totalItems === tempSelectedValues.length) {
      setClearClickFlag(false);
    }
  };

  const renderSelectAllCheckBox = () => {
    return (
      <DropDown.Item
        key="select-all"
        id={`${staticIds.account360.engagedPeopleDropDownList}select_all`}
        className={`${
          selectAll
            ? styles.salesRepresentativeDropDownBtnItemListStyled
            : styles.salesRepresentativeDropDownBtnItemListUnStyled
        }`}
        onClick={toggleSelectAll}
      >
        <div className={styles.listItem} key="all-checkbox">
          <ClayCheckbox
            checked={selectAll}
            className={styles.salesRepresentativeDropDownBtnCheckBox}
            onChange={() => {}}
          />
          <div className={styles.salesRepresentativeDropDownBtnTextBox}>
            {staticStrings.dropdownAllabel}
          </div>
        </div>
      </DropDown.Item>
    );
  };

  const renderDropDownItems = (item: IList, index: number) => {
    const value = item.name ?? item.value;
    return (
      <DropDown.Item
        id={`${staticIds.account360.engagedPeopleDropDownList}${index}`}
        key={item.value}
        className={`${
          tempSelectedValues.includes(item.value)
            ? styles.salesRepresentativeDropDownBtnItemListStyled
            : styles.salesRepresentativeDropDownBtnItemListUnStyled
        }`}
        onClick={() => {
          toggleCheckbox(item.value);
        }}
      >
        <div className={styles.listItem} key={item.value}>
          <ClayCheckbox
            checked={tempSelectedValues.includes(item.value)}
            className={styles.salesRepresentativeDropDownBtnCheckBox}
            onChange={() => {}}
          />
          <div className="content">
            <div className={styles.salesRepresentativeDropDownBtnTextBox}>
              {value}
            </div>

            {displaySubheading && (
              <div className={styles.email}>{item.value}</div>
            )}
          </div>
        </div>
      </DropDown.Item>
    );
  };

  const renderDropDownSection = () => {
    return (
      <div className={styles.lazyLoader}>
        {searchKeyWord ? (
          dropdownList?.length > 0 && (
            <>
              {isSelectAll && renderSelectAllCheckBox()}
              {dropdownList?.map((item: IList, index: number) => {
                return item && renderDropDownItems(item, index);
              })}
            </>
          )
        ) : (
          <LazyLoadingContainer
            onScrollBottom={loadMoreAccounts}
            showLoader={isLoading}
            id={"engaged-dropdown"}
          >
            {" "}
            {dropdownList?.length > 0 && (
              <>
                {isSelectAll && renderSelectAllCheckBox()}
                {dropdownList?.map((item: IList, index: number) => {
                  return item && renderDropDownItems(item, index);
                })}
              </>
            )}
          </LazyLoadingContainer>
        )}
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchKeyWord = useCallback(
    debounce((keyWord: string) => {
      setSearchKeyWord(keyWord);
    }, 600),
    []
  );

  const handleSearch = (value: string) => {
    setSearchInput(value);
    debouncedSetSearchKeyWord(value);
  };

  useEffect(() => {
    if (!searchKeyWord && searchInput) setSearchInput("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyWord]);

  const handleDropdownClose = () => {
    setOpen(false);
    setTempSelectedValues(selectedValues);
    setTempUnselectedValues(unselectedValues);

    handleSearch("");
  };
  useEffect(() => {
    if (!clearClickFlag) {
      const tempselected = Array.from(
        new Set([...tempSelectedValues, ...selectedValues])
      );
      const finalTempSelelcted = tempselected.filter(
        (item) => !tempUnselectedValues.includes(item)
      );
      setTempSelectedValues(finalTempSelelcted);
    }
    if (dropDownUpdateFlag) setDropDownUpdateFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, unselectedValues]);
  useEffect(() => {
    if (open) {
      divRef &&
        divRef.current?.scroll({
          top: 0,
          behavior: "auto"
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, divRef, searchKeyWord]);

  useEffect(() => {
    setSubmitDisableFlag(
      HelperFunctions.arraysHaveSameValues(
        selectedValues,
        tempSelectedValues
      ) || tempSelectedValues?.length === 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, tempSelectedValues]);

  return (
    <div className={styles.salesRepresentative}>
      <div>
        <DropDown
          active={open}
          onActiveChange={(value: boolean) => {
            setOpen(value);
          }}
          closeOnClickOutside={true}
          className={styles.salesRepresentativeDropDown}
          trigger={
            <ClayButton
              displayType="unstyled"
              className={styles.salesRepresentativeDropDownBtn}
              onClick={() => {
                if (!open) handleDropdownClose();
                setOpen(!open);
              }}
              id="people_filter_dropdown"
            >
              <div className={styles.salesRepresentativeDropDownText}>
                {dropdownLabel}
              </div>
              {!open ? (
                <ClayIcon
                  symbol="angle-down"
                  spritemap={spritemap}
                  className={styles.arrow1}
                />
              ) : (
                <ClayIcon
                  symbol="angle-up"
                  spritemap={spritemap}
                  className={styles.arrow2}
                />
              )}
            </ClayButton>
          }
        >
          <div
            className={`${styles.dropDownSearchInput} ${styles.dropDownSearchInputContainerMinimised}`}
          >
            <SearchInput
              onChange={handleSearch}
              searchValue={searchInput}
              placeholder={staticStrings.account360.accountdropdown.Search}
            />
          </div>

          <DropDown.ItemList
            className={styles.salesRepresentativeDropDownBtnItemList}
            id="engaged-dropdown"
            ref={divRef}
          >
            {isLoadingInitialData ? (
              <ContainerLoader msgRequired={false} />
            ) : (
              <div className={styles.accountDropDownListContainerMinimised}>
                {renderDropDownSection()}
              </div>
            )}
          </DropDown.ItemList>

          <DropDown.Section
            className={styles.salesRepresentativeDropDownFooterContainer}
          >
            <ClayButton.Group spaced>
              {btnClass !== staticStrings.userManagement.addUserClassBtn &&
                !isLoadingInitialData &&
                dropdownList?.length > 0 && (
                  <>
                    <CustomButton
                      displayType="secondary"
                      onClick={handleClear}
                      className={styles.clearButton}
                      id="people_filter_clear"
                    >
                      {staticStrings.account360.engagedPeople.clear}
                    </CustomButton>

                    <CustomButton
                      displayType="primary"
                      onClick={handleSubmit}
                      className={styles.submitButton}
                      disabled={
                        HelperFunctions.arraysHaveSameValues(
                          selectedValues,
                          tempSelectedValues
                        ) ||
                        tempSelectedValues?.length === 0 ||
                        dropDownUpdateFlag
                      }
                      id="people_filter_submit"
                    >
                      {staticStrings.account360.engagedPeople.done}
                    </CustomButton>
                  </>
                )}
            </ClayButton.Group>
          </DropDown.Section>
          {!isLoadingInitialData && !isLoading && dropdownList.length === 0 && (
            <DropDown.Section className={`${styles.dropDownContainerWidth}`}>
              <h4 className={styles.textContent}>
                {staticStrings.commonTexts.noRecordsFound}
              </h4>
            </DropDown.Section>
          )}
        </DropDown>
      </div>
    </div>
  );
};

export default DropdownCheckBoxWithLazyLoading;
