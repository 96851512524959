import React from "react";
import ClayForm, { ClayInput } from "@clayui/form";
import { ErrorMessage, Field } from "formik";
import TextError from "./TextError";
import styles from "./Controls.module.scss";
import LoadingGif from "@images/loading-circle-black.gif";
interface ITextProps {
  name: string;
  label: string;
  placeholder: string;
  disabled?: boolean;
  isLoading: boolean;
}
const SearchWithLoader: React.FC<ITextProps> = ({
  name,
  label,
  placeholder,
  disabled,
  isLoading
}) => {
  return (
    <React.Fragment>
      {label && (
        <label htmlFor={name} className={styles.labelText}>
          {label}
        </label>
      )}
      <Field name={name}>
        {({ form, field }: { form: any; field: any }) => {
          const { value, onTypeShow } = field;
          return (
            <>
              <ClayForm.Group
                className={`form-group-sm ${
                  form.errors[name] && form.touched[name] !== undefined
                    ? "has-error"
                    : ""
                } ${styles.textContainer} ${styles.searchInputGroup}`}
              >
                <ClayInput.GroupItem className={styles.searchGroupItem}>
                  <ClayInput
                    type="text"
                    value={value}
                    placeholder={placeholder || label}
                    {...field}
                    className={`${styles.formButton} ${styles.searchInput} ${styles.borderNone}`}
                    disabled={disabled}
                    onKeyUp={() => form.setFieldTouched(name)}
                  />
                </ClayInput.GroupItem>
                {isLoading && (
                  <ClayInput.GroupItem
                    shrink
                    className={styles.searchGroupItem}
                  >
                    <ClayInput.GroupText className={styles.closeIconContainer}>
                      <img
                        className={styles.containerLoaderIcon}
                        src={LoadingGif}
                        alt={"Loading ..."}
                      />
                    </ClayInput.GroupText>
                  </ClayInput.GroupItem>
                )}
              </ClayForm.Group>
              <div
                className={`form-group-sm ${
                  form.errors[name] && form.touched[name] !== undefined
                    ? "has-error"
                    : ""
                } ${styles.textContainer}`}
              >
                {!onTypeShow && (
                  <ErrorMessage name={name} component={TextError} />
                )}
              </div>
            </>
          );
        }}
      </Field>
    </React.Fragment>
  );
};

export default SearchWithLoader;
