import Sprite from "@images/clay/sprite.svg";
import IconSprite from "@images/sprite-icons.svg";

const claySpriteImagePath = () => {
  return Sprite;
};

const iconSpriteImagePath = () => {
  return IconSprite;
};

export { claySpriteImagePath, iconSpriteImagePath };
