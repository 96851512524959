import styles from "./CustomButton.module.scss";
import ClayButton from "@clayui/button";

interface ButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
  displayType?: "primary" | "secondary" | "reset";
  className?: string;
  disabled?: boolean;
  type?: "reset" | "button" | "submit";
  id: string;
}

type StyleDictionary = {
  [key: string]: string;
};

const buttonStyle: StyleDictionary = {
  primary: styles.primaryButton,
  secondary: styles.secondaryButton,
  reset: styles.resetButton
};

const CustomButton: React.FC<ButtonProps> = ({
  onClick,
  children,
  displayType,
  className,
  disabled,
  type,
  id
}) => {
  return (
    <ClayButton
      className={`${styles.baseButton} ${
        displayType ? buttonStyle[displayType] : null
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      id={id}
    >
      {children}
    </ClayButton>
  );
};

export default CustomButton;
