import { ErrorBoundary } from "react-error-boundary";
import { OktaAuthentication } from "@common-components";

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div role="alert">
      <div className="m-5">
        <p>{`Something went wrong: "${error}".`}</p>
        <p>{`Please refresh or login to another window.`}</p>
      </div>
    </div>
  );
}

function AppRoutes() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="brdcm-container">
        <>
          <OktaAuthentication />
        </>
      </div>
    </ErrorBoundary>
  );
}

export default AppRoutes;
